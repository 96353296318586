import React from 'react'
import { CAlert, CToaster, CCarousel, CCarouselItem, CImage, CSpinner, CContainer } from '@coreui/react'
import { TextField, Box, Grid, Button } from '@mui/material'

import CustHeader from 'sections/layout/dashboard/CHeader'
import Modal from 'components/uiElements/modal/Dialog'
import CHeaderBrands from 'sections/layout/buyer/CHeaderBrands'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'
import ChatBox from 'components/chat/chatBox'
import Seo from 'components/common/seo'
import Loader from 'components/common/Loader'
import PropTypes from 'prop-types'

import AlertMessage from 'components/uiElements/AlertMessage'
import ProductTile from 'components/uiElements/ProductTile'
import Filter from 'components/uiElements/sidefilter/Filter'
import { SortFilter } from 'components/uiElements'
import GoTop from 'components/common/GoTop'
import Drawer from 'components/uiElements/drawer'
import DrawerButton from 'components/uiElements/drawer/DrawerButton'
import { AppFooter } from 'sections/layout/dashboard'
import Constants from 'data/Constants'
import PageNotFound from '../PageNotFound'
import SlideShow from '../../components/uiElements/SlideShow'
import { useECatalogueHooks } from './useECatalogueHooks'

const ECatalogue = (props) => {
  const {
    id,
    isDemoMode,
    isLoading,
    codes,
    products,
    sortedItems,
    setSortedItems,
    company,
    name,
    pin,
    userId,
    validPIN,
    shortlisted,
    toast,
    open,
    error,
    messages,
    setMessages,
    templateCode,
    isQuotationShared,
    toaster,
    isOpen,
    setIsOpen,
    filterDrawer,
    selected,
    setSelected,
    responseError,
    slideShow,
    values,
    setSlideShow,
    toggleDrawer,
    queryCreated,
    shortlistProduct,
    removeProduct,
    handleClose,
    handleSuccess,
    handleChange,
    downloaded,
    openedProducts,
    updateCatalogueInfo,
    catalogueData,
    fetchProducts,
    isNegotiated,
    setIsNegotiated
  } = useECatalogueHooks({ ...props })

  return (
    <>
      {responseError ? (
        <PageNotFound message={error} />
      ) : (
        <>
          {name !== undefined && (
            <Seo
              title={`${name} eCatalogue`}
              description={`${company}'s ${name} eCatalogue - View & Browse our Collection`}
            />
          )}
          <div className='wrapper d-flex flex-column bg-light'>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Modal
                  open={open && props.Ecatalogue}
                  title={<CHeaderBrands company={company} name={name} />}
                  content={
                    <>
                      {!!error && <CAlert color='danger'>{error}</CAlert>}
                      <TextField
                        margin='dense'
                        id='fName'
                        label='Your Name (Optional)'
                        type='text'
                        fullWidth
                        variant='standard'
                        onChange={handleChange('fName')}
                      />
                      <TextField
                        margin='dense'
                        id='email'
                        label='E-Mail Address (Optional)'
                        type='email'
                        fullWidth
                        variant='standard'
                        onChange={handleChange('email')}
                      />
                      {isNotUndefinedAndNull(pin) && pin !== 0 && (
                        <TextField
                          margin='dense'
                          id='pin'
                          label='PIN'
                          type='number'
                          onChange={handleChange('pin')}
                          fullWidth
                          variant='standard'
                          required={pin !== 0}
                        />
                      )}
                    </>
                  }
                  isDialogReq={isNotUndefinedAndNull(pin) && pin !== 0}
                  handleAction={handleSuccess}
                  actionTitle='View Catalogue'
                  handleClose={handleClose}
                />
                {(!isNotUndefinedAndNull(pin) || pin === 0 || validPIN === true) && (
                  <>
                    <CustHeader
                      company={company}
                      name={name}
                      products={products}
                      shortlisted={shortlisted}
                      id={id}
                      downloaded={downloaded}
                      IsCatalogue={props.Ecatalogue}
                      templateCode={templateCode}
                      code={values.code}
                    />
                    <AlertMessage
                      isEnabled={isDemoMode}
                      message={
                        <>
                          <strong>Test Link</strong> - Do Not Share this Link With Buyers
                        </>
                      }
                    />
                    <div className='body flex-grow-1 px-3 pb-4'>
                      <CContainer lg>
                        <CCarousel
                          data-testid='Carousel'
                          controls
                          indicators
                          className='d-none d-sm-block'
                          fallback={<CSpinner color='primary' />}
                        >
                          {isNotNullUndefinedOrEmpty(company) &&
                            isNotNullUndefinedOrEmpty(company.companyHeaders) &&
                            company.companyHeaders.map((header, index) => {
                              return (
                                <CCarouselItem key={index}>
                                  <CImage
                                    className='d-block w-100'
                                    height='250rem'
                                    src={header}
                                    alt={`slider${index}`}
                                  />
                                </CCarouselItem>
                              )
                            })}
                        </CCarousel>
                        <Drawer open={filterDrawer} handleDrawer={toggleDrawer}>
                          <Box sx={{ width: 300 }} role='presentation'>
                            <Filter
                              selected={selected}
                              setSelected={setSelected}
                              data={products}
                              filteredData={sortedItems}
                              filters={[
                                { name: 'type', label: 'Type' },
                                { name: 'subCategory', label: 'Category' },
                                { name: 'construction', label: 'Construction' }
                              ]}
                              setFilteredData={setSortedItems}
                            />
                          </Box>
                        </Drawer>
                        <Grid
                          container
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          className='mt-4 mb-3'
                        >
                          <Grid item lg={1} md={2}  sm={5}>
                            <DrawerButton
                              tooltip='Filter Products'
                              label={`${selected.length > 0 ? selected.length + ' Filters Selected' : 'Filter'}`}
                              handleClick={toggleDrawer}
                            />
                          </Grid>
                          <Grid item lg={9} >
                            <SortFilter
                              items={products}
                              setItems={setSortedItems}
                              sortOnData={Constants.sortProductDropdown}
                            />
                          </Grid>
                          <Button
                            variant='outlined'
                            sx={{
                              border: '1px solid #ced4da',
                              height: '45px',
                              marginTop: '1.2rem',
                              width: { xs:'100%', sm:'55%', md:'30%', lg:'10%'}
                            }}
                            label='SlideShow'
                            onClick={(e) => {
                              e.preventDefault()
                              setSlideShow(true)
                            }}
                          >
                            SlideShow
                          </Button>
                        </Grid>
                        <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='flex-start'>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
                              {sortedItems !== undefined &&
                                sortedItems.map((product, index) => (
                                  <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                    <ProductTile
                                      key={index}
                                      index={index}
                                      product={product}
                                      shortlistProduct={shortlistProduct}
                                      removeProduct={removeProduct}
                                      openedProducts={openedProducts}
                                      isCustomerView
                                      fallback={<CSpinner color='primary' />}
                                      setIsOpen={setIsOpen}
                                      isViewOnly={!props.Ecatalogue}
                                      isQuotationShared={isQuotationShared}
                                      updateCatalogueInfo={updateCatalogueInfo}
                                      catalogueData={catalogueData}
                                      fetchProducts={fetchProducts}
                                      eCatalogue={props.Ecatalogue}
                                      isNegotiated={isNegotiated}
                                      setIsNegotiated={setIsNegotiated}
                                    />
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <GoTop />
                      </CContainer>
                    </div>

                    {props.Ecatalogue ? (
                      <ChatBox
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        codes={codes}
                        teamName={company.name || 'Send A Query'}
                        imageUrl={company.logo}
                        shortlisted={shortlisted}
                        catalogueId={id}
                        messages={messages}
                        setMessages={setMessages}
                        companyId={company.companyId}
                        userId={userId}
                        catalogueName={name}
                        queryCreated={queryCreated}
                        isDisabled={isDemoMode}
                      />
                    ) : (
                      <></>
                    )}

                    {slideShow ? (
                      <SlideShow products={products} setSlideShow={setSlideShow} slideShow={slideShow} />
                    ) : (
                      <></>
                    )}

                    <CToaster ref={toaster} push={toast} placement='bottom-start' />
                    <AppFooter isImageFooter />
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
ECatalogue.propTypes = {
  ShopName: PropTypes.string,
  Ecatalogue: PropTypes.bool
}
export default ECatalogue
