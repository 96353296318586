import React from 'react'
import { Grid, Tooltip, Button, Typography } from '@mui/material'
import { FcFilledFilter } from 'react-icons/fc'
import PropTypes from 'prop-types'

import CustomisedInput from 'components/formElements/CustomisedInput'

const DrawerButton = (props) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-end'
      alignItems='flex-end'
    >
      <Tooltip title={props.tooltip}>
        <Grid item sx={{ marginTop: '1.3rem' }}>
          <Button 
            input={<CustomisedInput />}
            variant='outlined' 
            sx={{
              border: '1px solid #ced4da',
              width:'100%',
              paddingRight: { xs: '1px',sm:'3px', md:'10px', lg:'10px'},
            }}
            startIcon={<FcFilledFilter size='2rem' />}
            onClick={props.handleClick}
          >
            <Typography sx={{ display: { xs: 'none', sm: 'block' }}}>
              {props.label}
            </Typography>
          </Button>
        </Grid>
      </Tooltip>
    </Grid>
  )
}

DrawerButton.propTypes = {
  handleClick: PropTypes.func,
  tooltip: PropTypes.string,
  label: PropTypes.string
}

export default DrawerButton
