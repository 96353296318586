import React from 'react'
import { Drawer as MUIDrawer } from '@mui/material'
import PropTypes from 'prop-types'

const Drawer = (props) => {
  const handleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    props.handleDrawer(event)
  }

  return (
    <MUIDrawer
      anchor='right'
      open={props.open}
      onClose={handleDrawer}
      variant='temporary'
      ModalProps={{
        keepMounted: true
      }}
    >
      {props.children}
    </MUIDrawer>
  )
}

Drawer.propTypes = {
  children: PropTypes.object,
  open: PropTypes.bool,
  handleDrawer: PropTypes.func
}

export default Drawer
