import React, { useEffect, useState } from 'react'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { CContainer, CHeader, CHeaderBrand, CHeaderNav, CHeaderToggler, CNavItem } from '@coreui/react'
// import { BiBell } from 'react-icons/bi'
import { BsEnvelope } from 'react-icons/bs'
import { RiMenuUnfoldLine } from 'react-icons/ri'
import PropTypes from 'prop-types'

import { AppHeaderDropdown } from './header/index'
import { pricingPlan, UserRoles } from 'data/Enums'
import { isAuthorized } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'
import catalogueServices from 'shared/services/catalogues.service'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: 2,
    top: -12,
    border: `2px solid ${theme.palette.background.paper}`
  }
}))

const AppHeader = (props) => {
  const [messagesCount, setMessageCount] = useState(0)
  const contextObj = React.useContext(UserContext)

  useEffect(() => {
    const getUserQueries = () => {
      catalogueServices.getUnreadUserQueresCount(contextObj.userData.user.id).then(
        (response) => {
          setMessageCount(response.data.messages)
        },
        () => {}
      )
    }
    const getCompanyQueries = () => {
      catalogueServices.getUnreadCompanyQueresCount(contextObj.userData.user.companyId, contextObj.userData.user.id).then(
        (response) => {
          setMessageCount(response.data.messages)
        },
        () => {}
      )
    }

    if (isAuthorized(contextObj.userData.user.pricingPlan, pricingPlan.Standard)) {
      if (UserRoles.SuperAdmin === contextObj.userData.user.role) {
        getCompanyQueries()
      } else {
        getUserQueries()
      }
    }
  }, [contextObj.userData])

  return (
    <CHeader position='fixed'>
      <CContainer fluid>
        <CHeaderToggler className='ps-1' onClick={props.handleToggle}>
          <RiMenuUnfoldLine />
        </CHeaderToggler>
        <CHeaderBrand className='mx-auto d-md-none' to='/'>
          <img src={require('assets/brand/logo_color.svg').default} height={48} alt='Logo' />
        </CHeaderBrand>

        <CHeaderNav className='d-none d-md-flex ms-auto'>
          {isAuthorized(contextObj.userData?.user?.pricingPlan, pricingPlan.Standard) && (
            <CNavItem>
              <StyledBadge
                badgeContent={messagesCount}
                color='success'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                <Link to='/messenger'>
                  <BsEnvelope className='notifications-icon' />
                </Link>
              </StyledBadge>
            </CNavItem>
          )}
          {/* <CNavItem>
            <Link to="/messages">
              <BsEnvelope className="notifications-icon" />
            </Link>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav className='ms-3'>
          <AppHeaderDropdown signOut={props.logOut} />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

AppHeader.propTypes = {
  logOut: PropTypes.func,
  currentUser: PropTypes.object,
  show: PropTypes.bool,
  handleToggle: PropTypes.func
}

export default AppHeader
