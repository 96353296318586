import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import authService from 'shared/services/auth.service'

export const UserContext = createContext()
export const AuthContextProvider = ({ children }) => {
  const getCurrentUser = authService.getCurrentUser()
  const [user, setUser] = React.useState(getCurrentUser || null)

  const setCurrentUser = () => {
    setUser(authService.getCurrentUser())
  }

  const contextObj = {
    userData: user,
    setUserData: setCurrentUser,
    setUser
  }
  return (
    <UserContext.Provider value={contextObj}>
      {children}
    </UserContext.Provider>
  )
}
AuthContextProvider.propTypes = {
  children: PropTypes.func
}
