import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Grid, Checkbox } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
// import { ImBoxAdd } from 'react-icons/im'
import { GrView, GrCheckbox, GrCheckboxSelected } from 'react-icons/gr'
import PropTypes from 'prop-types'

import Modal from 'components/uiElements/modal/Dialog'
import View from 'components/products/product/View'

import { isNotNullUndefinedOrEmpty, randomIntFromInterval, isAuthorized } from 'shared/util/helper'

import { UserContext } from 'context/AuthContext/UserContext'
import Images from 'data/Images'
import { UserRoles } from 'data/Enums'
import Constants from 'data/Constants'

import './Tile.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

const Tile = (props) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const random = randomIntFromInterval(1, 3)
  const contextObj = React.useContext(UserContext)

  const [isChecked, setIsChecked] = useState(props.checked?.indexOf(props.index) > -1)
  const handleChange = (event) => {
    const selectedElements = props?.checked
    const selectedProducts = props?.selectedProducts
    const elementIndex = props?.checked?.indexOf(event?.target?.id)
    if (elementIndex > -1) {
      selectedElements.splice(elementIndex, 1)
      selectedProducts.splice(elementIndex, 1)
    } else {
      selectedElements.push(event?.target?.id)
      selectedProducts.push(props.product)
    }
    props.setChecked([...selectedElements])
    props.setSelectedProducts([...selectedProducts])
  }

  useEffect(() => {
    setIsChecked(props?.checked?.indexOf(props?.index) > -1)
  }, [props?.checked])

  return (
    <>
      <div className='card-tile' role='button'>
        <div className='content'>
          <div className={`front ${isChecked ? 'front-effect-checked' : ''}`}>
            <LazyLoadImage
              className='profile'
              width='100%'
              src={isNotNullUndefinedOrEmpty(props.item.images[0]) ? props.item.images[0] : Images.ImageFiller}
              alt={props.item.code}
              effect='blur'
              placeholderSrc={Constants.ImageFiller}
              threshold={50}
            />
            <span className={`item-title ${props.checked.length > 0 ? 'item-title-checked' : ''}`}>
              <div>
                <strong data-testid='code'>Code:</strong>
                {props.item.code.length > 7 ? props.item.code.substring(0, 4) + "..." : props.item.code}
              </div>
              <div className='for-checkbox'>
                {props.checked.length > 0 && (
                  <Checkbox
                    data-testid={props.index}
                    icon={<GrCheckbox />}
                    checkedIcon={<GrCheckboxSelected />}
                    id={props.index}
                    checked={isChecked}
                    onClick={(e) => handleChange(e)}
                  />
                )}
              </div>
            </span>
          </div>
          {!props.checked.length > 0 && (
            <div className={`back ${random === 1 ? 'from-left' : random === 2 ? 'from-bottom' : 'from-right'}`}>
              <p className='product-name'>{props.item.name}</p>
              <p className='product-code'>
                <strong>Code:</strong>
                {props.item.code}
              </p>
              {props.item.isNew && (
                <img className='tem-img' src={require('assets/svgs/innosales/new.svg').default} alt='' />
              )}
              &nbsp;
              {props.item.isExclusive && (
                <img className='tem-img' src={require('assets/svgs/innosales/exclusive.svg').default} alt='' />
              )}
              <br />
              {props.item.discription && <p className='des'>{props.item.discription}</p>}
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                {isAuthorized(contextObj.userData?.user?.role, UserRoles.Write) && (
                  <Tooltip title='Edit Product'>
                    <Grid item>
                      <AiOutlineEdit
                        size='1.2em'
                        color='#ff9f80'
                        onClick={() => navigate(`/products/${props.item.id}`)}
                      />
                    </Grid>
                  </Tooltip>
                )}
                {isAuthorized(contextObj.userData?.user?.role, UserRoles.Admin) && (
                  <Tooltip title={`Delete ${props.item.code}`}>
                    <Grid item>
                      <AiOutlineDelete
                        size='1.2em'
                        data-testid='delete'
                        color='#ff6666'
                        onClick={(e) => {
                          e.preventDefault()
                          props.deleteProduct(props.item.id)
                        }}
                      />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
              <Grid container direction='row' justifyContent='center' alignItems='center' className='social-icon'>
                <Tooltip title='View Product'>
                  <Grid item xs={3}>
                    <GrView
                      color='#6495ED'
                      className='card-tile-actions'
                      onClick={(e) => {
                        e.preventDefault()
                        setOpen(true)
                      }}
                      size='1.2em'
                    />
                  </Grid>
                </Tooltip>
                {isAuthorized(contextObj.userData?.user?.role, UserRoles.Write) && (
                  <Tooltip title={`Select ${props.item.code}`}>
                    <Grid item xs={3}>
                      <Checkbox
                        data-testid={props.index}
                        icon={<GrCheckbox />}
                        checkedIcon={<GrCheckboxSelected />}
                        id={props.index}
                        checked={isChecked}
                        onClick={(e) => handleChange(e)}
                      />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={open}
        fullWidth
        maxWidth='md'
        content={
          <View
            productId={props.item.id}
            isUserView
            deleteProduct={props.deleteProduct}
            needReview={props.needReview}
            approveBtnClick={props.approveBtnClick}
            index={props.index}
          />
        }
        handleClose={() => setOpen(false)}
      />
    </>
  )
}

Tile.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  deleteProduct: PropTypes.func,
  setChecked: PropTypes.func,
  checked: PropTypes.array,
  needReview: PropTypes.bool,
  approveBtnClick: PropTypes.func,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  product: PropTypes.object
}

export default Tile
