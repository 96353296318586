import React, { useState } from 'react'
import { List, ListItem, Collapse, Grid, TextField, InputAdornment } from '@mui/material'
import { IoIosArrowDown } from 'react-icons/io'
import { BiSearchAlt } from 'react-icons/bi'
import PropTypes from 'prop-types'

import './NestedItem.css'

const NestedItem = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleIsOpen = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <List className='filter-list'>
      <ListItem button onClick={handleIsOpen}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <span data-testid={props.title} className='item-label'>
              {props.title} ({props.subItemsLength})
            </span>
          </Grid>
          <Grid item>
            <IoIosArrowDown
              data-testid='arrowDown'
              className={`d-tree-toggler ${isOpen ? 'active' : ''}`}
            />
          </Grid>
        </Grid>
      </ListItem>

      <Collapse in={isOpen}>
        {props.subItemsLength > 5 &&
       (
         <Grid
           container
           direction='row'
           justifyContent='flex-start'
           alignItems='center'
         >
           <Grid item xs={10}>
             <TextField
               data-testid='subSearch'
               id='Subsearch-box'
               label='Search'
               variant='standard'
               onChange={(e) => props.onChangeHandler(e, props.name)}
               sx={{ marginLeft: '10%' }}
               InputProps={{
                 endAdornment: (
                   <InputAdornment position='end'>
                     <BiSearchAlt />
                   </InputAdornment>
                 )
               }}
             />
           </Grid>
         </Grid>
       )}
        <List>{props.children}</List>
      </Collapse>
    </List>
  )
}

NestedItem.propTypes = {
  subItems: PropTypes.array,
  data: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.object,
  onChangeHandler: PropTypes.func,
  name: PropTypes.string,
  subItemsLength: PropTypes.number
}

export default NestedItem
