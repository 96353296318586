import axios from 'axios'

const getLocation = async () => {
  return await axios.get(process.env.REACT_APP_LOCATION_API, {
    timeout: process.env.REACT_APP_API_TIMEOUT
  })
}

const locationServices = {
  getLocation
}

export default locationServices
