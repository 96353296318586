import React, { useState } from 'react'
import { Card, CardContent, Box, Tooltip } from '@mui/material'
import { BsFillCartFill } from 'react-icons/bs'
import { MdAddShoppingCart } from 'react-icons/md'
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im'
import PropTypes from 'prop-types'

import Dialog from './modal/Dialog'
import View from 'components/products/product/View'
import { isNotNullUndefinedOrEmpty, IsTrue } from 'shared/util/helper'

import './ProductTile.css'

import Constants from 'data/Constants'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ProductTile = (props) => {
  const [IsShortlistedProduct, setIsShortlistedProduct] = useState(props.isShortlistedProduct)
  const [Open, setOpen] = useState(false)

  const addtoShortlist = (e) => {
    e.preventDefault()
    setIsShortlistedProduct(true)
    props.shortlistProduct(props.product)
  }

  const removeFromShortlist = (e) => {
    e.preventDefault()
    setIsShortlistedProduct(false)
    props.removeProduct(props.product)
  }

  const handleModalOpen = () => {
    if (props.isCustomerView) props.openedProducts(props.product.productId)
    setOpen(true)
  }
  return (
    <div>
      <Card
        sx={{
          height: '15rem',
          maxWidth: '14rem',
          maxHeight: '21rem'
        }}
        className='mb-3 custom-card-classes'
      >
        <Box
          sx={{
            height: '12rem',
            maxHeight: '17rem',
            position: 'relative'
          }}
        >
          <LazyLoadImage
            style={{ cursor: 'pointer' }}
            onClick={handleModalOpen}
            component='img'
            height='100%'
            width='100%'
            src={isNotNullUndefinedOrEmpty(props.product.images[0]) ? props.product.images[0] : Constants.ImageFiller}
            effect='blur'
            placeholderSrc={Constants.ImageFiller}
            threshold={50}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              color: 'white',
              paddingTop: '10px'
            }}
          >
            {IsTrue(props.product.isNew) && (
              <div>
                <span className='badge ms-1 rounded-pill bg-success tag-custom'>New</span>
              </div>
            )}
            {IsTrue(props.product.isExclusive) && (
              <div>
                <span className='badge ms-1 rounded-pill bg-info tag-custom'>Exclusive</span>
              </div>
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              color: 'white',
              paddingTop: '10px'
            }}
          >
            {!props.isViewOnly && (
              <>
                {props.isCustomerView ? (
                  <Tooltip title={`${IsShortlistedProduct ? 'Remove from Shortlist' : 'Add to Shortlist'}`}>
                    <div
                      data-testid='checkbox'
                      role='button'
                      className={`save-btn ${IsShortlistedProduct ? 'shortlisted' : ''}`}
                      tabIndex={0}
                      onClick={(e) => {
                        e.preventDefault()
                        IsShortlistedProduct ? removeFromShortlist(e) : addtoShortlist(e)
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                    >
                      {IsShortlistedProduct ? (
                        <>
                          <BsFillCartFill size='1.5em' />
                        </>
                      ) : (
                        <MdAddShoppingCart size='1.5em' />
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    data-testid='un-checkbox'
                    role='button'
                    className={`save-btn ${IsShortlistedProduct ? 'shortlisted' : ''}`}
                    tabIndex={0}
                    onClick={(e) => {
                      e.preventDefault()
                      IsShortlistedProduct ? removeFromShortlist(e) : addtoShortlist(e)
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                  >
                    {IsShortlistedProduct ? (
                      <>
                        <ImCheckboxChecked size='1.4em' />
                      </>
                    ) : (
                      <ImCheckboxUnchecked size='1.4em' />
                    )}
                  </div>
                )}
              </>
            )}
          </Box>
        </Box>
        <CardContent className='product-code'>
          <strong className='grey-color'>Code:</strong>&nbsp;&nbsp;
          <Tooltip title={props.product.code}>
            <span className='grey-color'>
              {props.product.code.length > 7 ? props.product.code.substring(0, 4) + '...' : props.product.code}
            </span>
          </Tooltip>
        </CardContent>
      </Card>
      <Dialog
        open={Open}
        fullWidth
        maxWidth='lg'
        content={
          <View
            productId={props.product.id}
            isUserView={false}
            IsShortlistedProduct={IsShortlistedProduct}
            removeFromShortlist={removeFromShortlist}
            addtoShortlist={addtoShortlist}
            key={props.index}
            isCustomerView={props.isCustomerView}
            product={props.product}
            {...props}
          />
        }
        handleClose={() => setOpen(false)}
        isDialogReq
      />
    </div>
  )
}

ProductTile.propTypes = {
  shortlistProduct: PropTypes.func,
  product: PropTypes.object,
  removeProduct: PropTypes.func,
  isCustomerView: PropTypes.bool,
  index: PropTypes.number,
  isShortlistedProduct: PropTypes.bool,
  setIsOpen: PropTypes.bool,
  openedProducts: PropTypes.func,
  isViewOnly: PropTypes.bool,
  key: PropTypes.string,
  catalogueData: PropTypes.array,
  fetchProducts: PropTypes.func,
  eCatalogue: PropTypes.bool,
  isNegotiated: PropTypes.bool,
  setIsNegotiated: PropTypes.func
}

export default ProductTile
