import React, { useEffect } from 'react'
// import messageHistory from './messageHistory'
import Launcher from './Launcher'
import './styles'
import PropTypes from 'prop-types'

import CatalogueServices from 'shared/services/catalogues.service'
import Images from 'data/Images'

const ChatBox = (props) => {
  const [newMessagesCount, setNewMessagesCount] = React.useState(0)

  useEffect(() => {
    const getQuery = () => {
      CatalogueServices.getQuery(props.catalogueId).then(
        (response) => {
          const messages = response.data.messages
          if (messages) {
            setNewMessagesCount(messages.filter((x) => x.author === 'company' && x.unread === true)?.length || 0)
          }
          if (
            messages !== undefined &&
            messages.length > 0 &&
            messages[messages.length - 1].type !== 'init' &&
            messages[messages.length - 1].author === 'buyer'
          ) {
            props.setMessages([
              ...messages,
              {
                type: 'init',
                author: 'company',
                createdOn: new Date(),
                data: {
                  text: 'Our Team is still working on your previous Query. In the meantime is there something else I may help you today?'
                },
                unread: true
              }
            ])
          } else if (messages === undefined || messages.length === 0) {
            props.setMessages([
              {
                type: 'init',
                author: 'company',
                createdOn: new Date(),
                data: { text: 'How may I help you?' },
                unread: true
              }
            ])
          } else {
            props.setMessages(messages)
          }
        },
        () => {
          props.setMessages((prevState) => [
            ...prevState,
            {
              type: 'init',
              author: 'company',
              createdOn: new Date(),
              data: { text: 'How may I help you?' },
              unread: true
            }
          ])
        }
      )
    }

    if (!props.isDisabled) getQuery()
  }, [])

  useEffect(() => {
    if (Array.isArray(props.codes) && props.codes.length > 0) {
      if (props.messages.length > 0 && props.messages[props.messages.length - 1].type !== 'list') {
        props.setMessages((prevState) => [
          ...prevState,
          ...[{ type: 'list', author: 'company', createdOn: new Date(), data: { list: props.codes }, unread: true }]
        ])
      } else {
        props.setMessages((prevState) => [
          ...prevState.slice(0, props.messages.length - 1),
          {
            ...prevState[props.messages.length - 1],
            data: { list: props.codes }
          }
        ])
      }
    }
  }, [props.codes])

  const _onMessageWasSent = async (message) => {
    const tmessages = props.messages
    tmessages
      .filter((x) => x.author === 'company')
      .forEach((message) => {
        message.unread = false
      })
    const messages = [...tmessages, message]

    // const messages = [...props.messages, message]
    props.setMessages([...props.messages, message])
    const input = {
      message,
      catalogueId: props.catalogueId,
      catalogueName: props.catalogueName,
      companyId: props.companyId,
      userId: props.userId,
      messages
    }
    const response = await CatalogueServices.createQuery(input)
    if (response.status === 201) {
      props.queryCreated()
    }
  }

  return (
    <div>
      <Launcher
        agentProfile={{
          teamName: props.teamName,
          imageUrl: props.imageUrl || Images.InnoAgeLogoWithBG
        }}
        onMessageWasSent={_onMessageWasSent}
        messageList={props.messages}
        newMessagesCount={newMessagesCount}
        setIsOpen={props.setIsOpen}
        isOpen={props.isOpen}
        isDisabled={props.isDisabled}
      />
    </div>
  )
}

ChatBox.propTypes = {
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  shortlisted: PropTypes.array,
  chatData: PropTypes.object,
  codes: PropTypes.array,
  teamName: PropTypes.string,
  imageUrl: PropTypes.string,
  catalogueId: PropTypes.string,
  companyId: PropTypes.string,
  userId: PropTypes.string,
  messages: PropTypes.array,
  setMessages: PropTypes.func,
  catalogueName: PropTypes.string,
  queryCreated: PropTypes.func,
  isDisabled: PropTypes.bool
}

export default ChatBox
