import React from 'react'
import { useLocation } from 'react-router-dom'
import { Breadcrumbs, Link } from '@mui/material'

import routes from 'routes/DashboardRoutes'

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute.name
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      breadcrumbs.push({
        pathname: currentPathname,
        name: getRouteName(currentPathname, routes),
        active: index + 1 === array.length
      })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <Breadcrumbs aria-label='breadcrumb' className='mb-2'>
      <Link
        component='a'
        href='/'
        color='inherit'
        aria-current='home'
        underline='hover'
      >Home
      </Link>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Link
            key={index}
            underline='hover'
            color={breadcrumb.active ? 'text.primary' : 'inherit'}
            href={breadcrumb.pathname}
            aria-current='page'
          >
            {breadcrumb.name}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default React.memo(AppBreadcrumb)
