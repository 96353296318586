import React from 'react'
import PropTypes from 'prop-types'

const TextMessage = (props) => {
  return (
    <div className='sc-message--text'>
      <p>{props.data.text}</p>
    </div>
  )
}

TextMessage.propTypes = {
  data: PropTypes.object
}

export default TextMessage
