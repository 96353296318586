import React from 'react'
import { CContainer, CHeader, CHeaderBrand, CHeaderNav } from '@coreui/react'
import PropTypes from 'prop-types'

import CHeaderDropdown from './header/CHeaderDropdown'
import CHeaderBrands from '../buyer/CHeaderBrands'
import './CHeader.css'

const CustHeader = (props) => {
  return (
    <CHeader position='fixed'>
      <CContainer fluid>
        <CHeaderBrand to='/'>
          <CHeaderBrands company={props.company} name={props.name} />
        </CHeaderBrand>
        <CHeaderNav className='ms-3'>{props.IsCatalogue ? <CHeaderDropdown {...props} /> : <></>}</CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

CustHeader.propTypes = {
  company: PropTypes.object,
  name: PropTypes.string,
  IsCatalogue: PropTypes.bool,
  code: PropTypes.string
}

export default CustHeader
