import React from 'react'
import { CgDanger } from 'react-icons/cg'
import PropTypes from 'prop-types'

import './AlertMessage.css'

const AlertMessage = (props) => {
  return (
    <>
      {props.isEnabled &&
        <div className='preview-only-message'><CgDanger color='red' size='1.9rem' />{'  '}
          <span className='pom-title'>{props.message}</span>
        </div>}
    </>
  )
}

AlertMessage.propTypes = {
  isEnabled: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default AlertMessage
