import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CToaster
} from '@coreui/react'
import { RiFilePpt2Line } from 'react-icons/ri'
import { AiOutlineShoppingCart, AiTwotoneFilePdf } from 'react-icons/ai'
import { BsFillCloudDownloadFill } from 'react-icons/bs'
import { IconButton, Badge } from '@mui/material'
import PropTypes from 'prop-types'
import { GeneratePPT } from 'shared/util/PPT'
import Modal from 'components/uiElements/modal/Modal'
import downloadIcon from 'assets/svgs/shared/download.svg'
import tickIcon from 'assets/svgs/shared/tick.svg'
import { exportToCSV } from 'shared/util/helper'
import catalogueServices from 'shared/services/catalogues.service'
import ToastMessage from 'components/uiElements/ToastMessage'
import { useRef } from 'react'
import { useState } from 'react'

const CHeaderDropdown = (props) => {
  const [toast, addToast] = useState(0)
  const toaster = useRef()
  const [open, setOpen] = React.useState(false)
  const [content, setContent] = React.useState(<></>)

  const handleClick = (e, products, isAllProducts = false) => {
    e.preventDefault()
    setOpen(true)
    populateContent(true)
    GeneratePPT(props.id, props.name, props.company, products, props.templateCode)
    populateContent(false)
    setTimeout(() => {
      setOpen(false)
    }, 1000)
    props.downloaded(isAllProducts)
  }
  const toastMessage = (isSuccess) => {
    return (
      <ToastMessage
        color={isSuccess ? 'success' : 'warning'}
        message={isSuccess ? <>Request for Purchase Order is sent</> : <>some error occurred</>}
      />
    )
  }

  const populateContent = (value) => {
    if (value === true) {
      setContent(
        <>
          <div className='mt-4'>
            <img style={{ padding: '30px' }} alt='' height='200px' width='400px' src={downloadIcon} />
            <div className='mt-4' style={{ textAlign: 'center' }}>
              <strong>Catalogue is getting downloaded</strong>
            </div>
          </div>
        </>
      )
    } else {
      setContent(
        <>
          <div className='mt-4'>
            <img style={{ padding: '30px' }} alt='' height='200px' width='400px' src={tickIcon} />
            <div className='mt-4' style={{ textAlign: 'center' }}>
              <strong>Catalogue Downloaded</strong>
            </div>
          </div>
        </>
      )
    }
  }

  const handlePoRequestForProducts = (products) => {
    const acceptedProducts = products.filter((product) => product.quotationStatus === 'Accepted')
    const acceptedProductIds = acceptedProducts.map((product) => product.productId)

    const input = {
      isPoRequested: true,
      poProductIds: acceptedProductIds
    }

    try {
      if (props.code) {
        catalogueServices.updateCatalogue(props.code, input).then(
          () => {
            addToast(toastMessage(true))
          },
          () => {}
        )
      }
    } catch {}
  }

  return (
    <>
      <CDropdown variant='nav-item'>
        <CDropdownToggle placement='bottom-end' className='py-0' caret={false}>
          <IconButton aria-label='cart' size='large'>
            <Badge badgeContent={props.shortlisted !== undefined && props.shortlisted.length} color='success'>
              <AiOutlineShoppingCart />
            </Badge>
          </IconButton>
        </CDropdownToggle>
        <CDropdownMenu className='pt-0' placement='bottom-end'>
          <CDropdownHeader className='bg-light fw-semibold py-2'>Entire Collection</CDropdownHeader>
          <CDropdownItem onClick={(e) => handleClick(e, props.products, true)}>
            <RiFilePpt2Line className='me-2' />
            Download PPT
            <CBadge color='info' className='ms-2'>
              {props.products !== undefined && props.products.length}
            </CBadge>
          </CDropdownItem>
          <CDropdownItem
            onClick={(e) => {
              e.preventDefault()
              exportToCSV(props.products, 'products')
            }}
          >
            <BsFillCloudDownloadFill className='me-2' />
            PIS Export
            <CBadge color='info' className='ms-2'>
              {props.products !== undefined && props.products.length}
            </CBadge>
          </CDropdownItem>
          <CDropdownItem
            onClick={(e) => {
              e.preventDefault()
              handlePoRequestForProducts(props.products)
            }}
          >
            <AiTwotoneFilePdf className='me-2' />
            Request Purchase Order
            <CBadge color='info' className='ms-2'>
              {props.products !== undefined &&
                props.products.filter((product) => product.quotationStatus === 'Accepted').length}
            </CBadge>
          </CDropdownItem>

          <CDropdownHeader className='bg-light fw-semibold py-2'>Shortlisted</CDropdownHeader>
          <CDropdownItem
            disabled={!(props.shortlisted !== undefined && props.shortlisted.length > 0)}
            onClick={(e) => handleClick(e, props.shortlisted)}
          >
            <RiFilePpt2Line className='me-2' />
            Download PPT
            <CBadge color='success' className='ms-2'>
              {props.shortlisted !== undefined && props.shortlisted.length > 0 ? props.shortlisted.length : ''}
            </CBadge>
          </CDropdownItem>
          <CDropdownItem
            onClick={(e) => {
              e.preventDefault()
              exportToCSV(props.shortlisted, 'products')
            }}
          >
            <BsFillCloudDownloadFill className='me-2' />
            PIS Export
            <CBadge color='info' className='ms-2'>
              {props.shortlisted !== undefined && props.shortlisted.length}
            </CBadge>
          </CDropdownItem>
          <CDropdownItem
            onClick={(e) => {
              e.preventDefault()
              handlePoRequestForProducts(props.shortlisted)
            }}
          >
            <AiTwotoneFilePdf className='me-2' />
            Request Purchase Order
            <CBadge color='info' className='ms-2'>
              {props.shortlisted !== undefined &&
                props.shortlisted.filter((product) => product.quotationStatus === 'Accepted').length}
            </CBadge>
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      <Modal open={open} fullWidth content={content} />
      <CToaster ref={toaster} push={toast} placement='bottom-start' />
    </>
  )
}

CHeaderDropdown.propTypes = {
  shortlisted: PropTypes.array,
  company: PropTypes.object,
  products: PropTypes.array,
  add: PropTypes.element,
  headCells: PropTypes.array,
  id: PropTypes.string,
  name: PropTypes.string,
  downloaded: PropTypes.func,
  templateCode: PropTypes.number,
  code: PropTypes.string
}

export default CHeaderDropdown
