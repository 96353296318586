import { React, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CCard,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import { Button, Snackbar, TextField, Alert as MuiAlert } from '@mui/material'
import { AiOutlineSend } from 'react-icons/ai'
import { forwardRef } from 'react'
// import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io'

const ProductQuotation = (props) => {
  const [isQuotation, setIsQuotation] = useState()
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })
  return (
    <>
      <CRow className='mb-4'></CRow>
      {isQuotation ? (
        <>
          <CRow className='mb-3'>
            <CCol sm={12}>
              <CRow>
                <CCol sm={4}>
                  <CRow>
                    <CCol sm={12}>
                      <TextField
                        id='outlined-cost'
                        label='Cost'
                        variant='outlined'
                        size='small'
                        name='cost'
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        value={props.values?.cost}
                        type='number'
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm={4}>
                  <CRow>
                    <CCol sm={12}>
                      <TextField
                        id='outlined-moq'
                        label='Moq'
                        variant='outlined'
                        size='small'
                        name='moq'
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        value={props.values?.moq}
                        type='number'
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm={4}>
                  <CRow>
                    <CCol sm={12}>
                      <TextField
                        id='outlined-productionLeadTime'
                        label='Production Lead Time'
                        variant='outlined'
                        size='small'
                        name='productionLeadTime'
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        value={props.values?.productionLeadTime}
                        type='number'
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow className='mb-3'>
            <CCol sm={12}>
              <CRow>
                <CCol sm={9}>
                  <CRow>
                    <CCol sm={12}></CCol>
                  </CRow>
                </CCol>
                <CCol sm={3}>
                  <Button
                    variant='outlined'
                    sx={{
                      border: '1px solid #ced4da',
                      height: '40px',
                      width: '190px'
                    }}
                    color='primary'
                    disabled={!props.values?.cost || !props.values?.moq || !props.values?.productionLeadTime}
                    onClick={(e) => props.updateCatalogueInfo(e)}
                    endIcon={<AiOutlineSend />}
                  >
                    Send Quotation
                  </Button>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </>
      ) : (
        ''
      )}
      <>
        <CRow className='mb-2'>
          <CCard>
            <CRow>
              <CCol sm={8} className='mt-2'>
                <h4>{props.product?.code}</h4>
              </CCol>
              <CCol sm={2} className='mt-1'>
                {props?.quotation[0]?.type &&
                props.product?.quotationStatus !== 'Accepted' &&
                props?.quotation[0]?.type !== 'Buyer' ? (
                  <Button
                    variant='outlined'
                    sx={{
                      border: '1px solid #ced4da',
                      height: '40px',
                      width: '130px'
                    }}
                    color='success'
                    onClick={() => {
                      props.acceptQuotation(props.index)
                    }}
                  >
                    Accept
                  </Button>
                ) : (
                  ''
                )}
              </CCol>
              <CCol sm={2} className='mt-1'>
                <Button
                  variant='outlined'
                  sx={{
                    border: '1px solid #ced4da',
                    height: '40px',
                    width: '130px'
                  }}
                  color='success'
                  onClick={() => {
                    setIsQuotation(true)
                  }}
                >
                  Quote
                </Button>
              </CCol>
            </CRow>
          </CCard>
        </CRow>
        <CRow className='ml-3'>
          <CTable className='ml-3'>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope='col'>#</CTableHeaderCell>
                <CTableHeaderCell scope='col'>Cost</CTableHeaderCell>
                <CTableHeaderCell scope='col'>MOQ</CTableHeaderCell>
                <CTableHeaderCell scope='col'>PLT</CTableHeaderCell>
                <CTableHeaderCell scope='col'>Type</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            {props.quotation?.map((quote, quoteIndex) => (
              <CTableBody key={quoteIndex}>
                <CTableRow>
                  <CTableHeaderCell scope='row'>{quoteIndex + 1}</CTableHeaderCell>
                  <CTableDataCell>{quote.cost}</CTableDataCell>
                  <CTableDataCell>{quote.moq}</CTableDataCell>
                  <CTableDataCell>{quote.productionLeadTime}</CTableDataCell>
                  <CTableDataCell>{quote.type}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            ))}
          </CTable>
        </CRow>
      </>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={props.open}
        autoHideDuration={6000}
        onClose={props.handleClose}
      >
        <Alert onClose={props.handleClose} severity={props.success ? 'success' : 'warning'} sx={{ width: '100%' }}>
          {props.message}
        </Alert>
      </Snackbar>
    </>
  )
}

ProductQuotation.propTypes = {
  isQuotationShared: PropTypes.bool,
  quotationRequested: PropTypes.bool,
  handleChange: PropTypes.func,
  product: PropTypes.array,
  updateCatalogueInfo: PropTypes.func,
  values: PropTypes.object,
  quotation: PropTypes.array,
  acceptQuotation: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  success: PropTypes.bool,
  message: PropTypes.string,
  index: PropTypes.number
}
export default ProductQuotation
