import { Grid } from '@mui/material'
import HeaderBtn from 'components/formElements/HeaderBtn'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import './Error.css'

const PageNotFound = (props) => {
  const navigate = useNavigate()
  return (
    <div className='container' id='notfound'>
      <div className='row error-wrapper'>
        <div className='col-12 text-center'>
          <div className='w-100 p-3 h-100 d-inline-block'>
            <div className='row'>
              <div className='col text-center'>
                <img
                  src={
                    props.message
                      ? require('../assets/images/error.png')
                      : require('../assets/svgs/shared/404.svg').default
                  }
                  alt='error'
                  style={{ height: '40vh' }}
                />
              </div>
            </div>
            <h4 className='mt-2'>{`${props.message || 'Oh no! Something went wrong...'}`}</h4>
            <p>The page you&apos;re looking for is Not Found </p>

            <p style={{ fontWeight: 550 }}>We suggest you Go Back</p>

            <Grid container className='mt20' direction='row' justifyContent='center' alignItems='center' spacing={3}>
              <Grid item>
                <div
                  role='button'
                  tabIndex={1}
                  onKeyDown={() => navigate(-1)}
                  onClick={() => navigate(-1)}
                  className='default-btn btn-bg-one border-radius-50'
                >
                  Go to Previous
                </div>
              </Grid>
              <Grid item>
                <HeaderBtn label='Go to Homepage' toUrl='/' />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}

PageNotFound.propTypes = {
  message: PropTypes.string
}
export default PageNotFound
