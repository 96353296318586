import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextMessage from './types/TextMessage'
import ShortlistMessage from './types/ShortlistMessage'
// import Images from 'data/Images'

class Message extends Component {
  _renderMessageOfType (type) {
    switch (type) {
      case 'text':
      case 'init':
        return <TextMessage {...this.props.message} />
      case 'list':
        return <ShortlistMessage {...this.props.message} />
      default:
        return ''
    }
  }

  render () {
    const contentClassList = ['sc-message--content', this.props.message.author === 'buyer' ? 'sent' : 'received']
    return (
      <div data-testid='message' className='sc-message'>
        <div className={contentClassList.join(' ')}>
          {this.props.message.author !== 'buyer' && (
            <div
              className='sc-message--avatar'
              style={{
                backgroundImage: `url(${this.props.companyImg})`
              }}
            />
          )}
          {this._renderMessageOfType(this.props.message.type)}
          {/* {this.props.message.author === 'buyer' && (
            <div
              data-testid='messageType'
              className='sc-message--avatar'
              style={{
                backgroundImage: `url(${Images.UserChatImage})`
              }}
            />
          )} */}
        </div>
      </div>
    )
  }
}

Message.propTypes = {
  message: PropTypes.object,
  companyImg: PropTypes.string
}

export default Message
