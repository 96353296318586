import React from 'react'
import Message from '.'
import PropTypes from 'prop-types'

const MessageList = (props) => {
  const messagesEndRef = React.useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    scrollToBottom()
  }, [props.messages])

  return (
    <div className='sc-message-list' data-testid='msgList'>
      {props.messages.map((message, i) => {
        return <Message message={message} key={i} companyImg={props.imageUrl} />
      })}
      <div ref={messagesEndRef} />
    </div>
  )
}
MessageList.propTypes = {
  messages: PropTypes.array,
  imageUrl: PropTypes.string
}
export default MessageList
