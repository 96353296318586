import axios from 'axios'
import authHeader from './auth-header'

const addProduct = (input) => {
  return axios.post(
    `${process.env.REACT_APP_API}/createproduct`,
    input,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getProductOnId = (productId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/product/${productId}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getProductOnShopName = (ShopName) => {
  return axios.get(
    `${process.env.REACT_APP_API}/shop/${ShopName}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const deleteProduct = (productId) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API}/product/${productId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const updateProduct = (id, input) => {
  return axios.put(
    `${process.env.REACT_APP_API}/product/${id}`,
    input,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getAllCompanyProduct = (companyId, productsPerPage, page, status) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getcompproducts/${companyId}/${productsPerPage}/${page}/${status}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const addBulkProduct = (input) => {
  return axios.post(
    `${process.env.REACT_APP_API}/importbulk`,
    input,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const getMetaDataOnId = (companyId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/cmetaproduct/${companyId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

// Dashboard
const getproduct = (companyId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getcompprodcount?companyId=${companyId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getproductStatus = (companyId) => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getproductStatus?companyId=${companyId}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}
const deleteMultipleProducts = (ids) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API}/multiple?productIds=${ids}`,
      {
        headers: authHeader()
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getProductsData = (productId) => {
  return axios.get(
    `${process.env.REACT_APP_API}/getData?productIds=${productId}`,
    {
      headers: authHeader()
    },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const updateMultipleProductsOnIds = (productIds, input) => {
  return axios.patch(
    `${process.env.REACT_APP_API}/updateProducts?id=${productIds}`,
    input,
    { headers: authHeader('application/json; charset=UTF-8') },
    { timeout: process.env.REACT_APP_API_TIMEOUT }
  )
}

const productServices = {
  getAllCompanyProduct,
  addProduct,
  addBulkProduct,
  getProductOnId,
  getProductOnShopName,
  updateProduct,
  getMetaDataOnId,
  deleteProduct,
  getproduct,
  getproductStatus,
  deleteMultipleProducts,
  getProductsData,
  updateMultipleProductsOnIds
}

export default productServices
