import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaPaperPlane } from 'react-icons/fa'

class SendIcon extends Component {
  render () {
    return (
      <button
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        onClick={(e) => {
          e.preventDefault()
          this.props.onClick(e)
        }}
        className='sc-user-input--send-icon-wrapper'
      >
        <FaPaperPlane className='send-icon' size='1.5em' />
      </button>
    )
  }
}

SendIcon.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func
}

export default SendIcon
