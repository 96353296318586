import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CCol, CRow } from '@coreui/react'
import { Button, Skeleton, Chip, Grid, Stack, Tooltip, IconButton, Snackbar, Alert as MuiAlert } from '@mui/material'
import { MdAttachMoney } from 'react-icons/md'
import { FaBookmark, FaRegBookmark } from 'react-icons/fa'
import { AiTwotoneEdit, AiOutlineDelete } from 'react-icons/ai'
import PropTypes from 'prop-types'

import {
  capitalizeFirstLetter,
  checkValues,
  isNotNullUndefinedOrEmpty,
  isAuthorized,
  isNotUndefinedAndNull,
  isArray
} from 'shared/util/helper'
import Carousel from './Carousel'
import Magnifier from '../../uiElements/Magnifier'
import Status from 'components/common/status/VerticalStatus'
import productServices from 'shared/services/products.service'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './View.css'
import Images from 'data/Images'
import Constants from 'data/Constants'
import { BsCheckLg } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import { UserRoles } from 'data/Enums'
import { UserContext } from 'context/AuthContext/UserContext'
import Dialog from 'components/uiElements/modal/Dialog'
import ProductQuotation from './ProductQuotation'
import catalogueServices from 'shared/services/catalogues.service'

const View = (props) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [product, setProduct] = useState({})
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [selectedImage, setSelectedImage] = useState()
  const [error, setError] = useState(null)
  const contextObj = React.useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)
  const [values, setValues] = useState({})
  const [quotation, setQuotation] = useState([])
  const [quotationLoading, setQuotationLoading] = useState([])

  const carouselItemsRef = useRef([])
  const [open, setOpen] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [success, setSuccess] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
    props.setIsNegotiated(false)
  }
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  const getProduct = async (productId) => {
    try {
      productServices.getProductOnId(productId).then(
        (response) => {
          setProduct(response.data)
          setIsLoading(false)
        },
        (error) => {
          setError(error.response ?? Constants.GenericError)
          setIsLoading(false)
        }
      )
    } catch {
      setError(Constants.GenericError)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (props.isNegotiated === 'requested' && props.product.quotationStatus === 'Requested') {
      setMessage('Request Sent for Quotation')
      setOpen(true)
      setSuccess(true)
    }
  }, [props.product, props.isNegotiated])

  useEffect(() => {
    if (!isNotNullUndefinedOrEmpty(props.product)) {
      getProduct(props.productId)
    } else {
      setProduct(props.product)
      setIsLoading(false)
    }
  }, [props.product])

  const getDimensions = (dimension) => {
    if (isNotNullUndefinedOrEmpty(dimension) && isNotUndefinedAndNull(dimension.shape)) {
      if (dimension.shape === 'Round') {
        return dimension.diameter
      } else if (dimension.shape === 'Rectangle') {
        return dimension.length + 'x' + dimension.breadth
      } else if (dimension.shape === 'Square') {
        return dimension.length
      } else if (dimension.shape === 'Other') {
        return dimension.custom
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  const getMaterial = (materials) => {
    if(Array.isArray(materials))
      {
        let product = ''
        product = materials.map((data) => {
          const formattedPercentage = Math.min(data.percentage, 100) > 0 ? `${Math.min(data.percentage, 100)}%` : ''
          return formattedPercentage
            ? `${capitalizeFirstLetter(data.name)}: ${formattedPercentage}`
            : capitalizeFirstLetter(data.name)
        }).join(' ')
        return product
    }
    else{
      const [name, percentage] = materials.split(":").map((part) => (isNaN(part) ? part : parseInt(part)))
      const formattedPercentage = Math.min(percentage, 100) > 0 ? `${Math.min(percentage, 100)}%` : ''
      return formattedPercentage
      ? `${capitalizeFirstLetter(name)}: ${formattedPercentage}`
      : capitalizeFirstLetter(name)
    }
  }

  const handleButtonClick = (e) => {
    if (props.IsShortlistedProduct) {
      props.removeFromShortlist(e)
    } else {
      props.addtoShortlist(e)
    }
    setIsLoading(false)
  }

  // For carousel
  useEffect(() => {
    if (product.images && product.images[0]) {
      carouselItemsRef.current = carouselItemsRef.current.slice(0, product.images.length)
      setSelectedImageIndex(0)
      setSelectedImage(product.images[0])
    }
  }, [product.images])

  // For Selecting Image
  const handleSelectedImageChange = (newIndex) => {
    if (product.images && product.images.length > 0) {
      setSelectedImage(product.images[newIndex])
      setSelectedImageIndex(newIndex)

      // Moving the Carousel towards the current index
      if (carouselItemsRef?.current[newIndex]) {
        carouselItemsRef?.current[newIndex]?.scrollIntoView({
          inline: 'center',
          behavior: 'smooth',
          block: 'center'
        })
      }
    }
  }
  const handleRightClick = () => {
    if (product.images && product.images.length > 0) {
      let newIndex = selectedImageIndex + 1

      // Start carousel from beginning
      if (newIndex >= product.images.length) {
        newIndex = 0
      }
      handleSelectedImageChange(newIndex)
    }
  }

  const handleLeftClick = () => {
    if (product.images && product.images.length > 0) {
      let newIndex = selectedImageIndex - 1

      // Start carousel from behind
      if (newIndex < 0) {
        newIndex = product.images.length - 1
      }
      handleSelectedImageChange(newIndex)
    }
  }

  const productQuotation = (e) => {
    if (product.quotationStatus !== 'Requested') {
      if (
        product.quotationStatus === 'Requested' ||
        product.quotationStatus === 'Quoted' ||
        props.catalogueData?.isQuotationShared
      ) {
        setIsOpen(true)
        getQuote()
      } else {
        props.updateCatalogueInfo(e, props.index)
      }
    }
  }
  const handleChange = (e) => {
    let tempValue = { ...values }
    tempValue[e.target.name] = e.target.value

    setValues({ ...tempValue })
  }

  const getQuote = () => {
    setQuotationLoading(true)
    catalogueServices.getQuotation(product.catalogueId, product.productId).then(
      (response) => {
        setQuotationLoading(false)

        setQuotation(response.data.quotionsResponse.reverse())
      },
      () => {
        setQuotationLoading(false)
      }
    )
  }

  useEffect(() => {
    if (product.catalogueId) {
      getQuote()
    }
  }, [product])

  const acceptQuotation = async (index) => {
    setError('')
    const input = [
      {
        path: `/product/${index}/cost`,
        op: 'replace',
        value: quotation[0].cost
      },
      {
        path: `/product/${index}/moq`,
        op: 'replace',
        value: quotation[0].moq
      },
      {
        path: `/product/${index}/productionLeadTime`,
        op: 'replace',
        value: quotation[0].productionLeadTime
      },
      {
        path: `/product/${index}/quotationStatus`,
        op: 'replace',
        value: 'Accepted'
      }
    ]

    catalogueServices.updateCatalogueInfo(product.catalogueId, JSON.stringify(input)).then(
      () => {
        setMessage('Quotation Sent !')
        setSuccess(true)
        toastMessage()
        setIsLoading(false)
        props.fetchProducts()
      },
      () => {
        setMessage('Some Error Occured, Please Try Again !')
        setSuccess(false)
        toastMessage()
      }
    )
  }

  const updateCatalogueInfo = async (e) => {
    e.preventDefault()
    const productData = getPatchData()
    let input = { ...productData }
    catalogueServices.sendQuotation(JSON.stringify(input)).then(
      () => {
        setMessage('Quotation Sent !')
        setSuccess(true)
        toastMessage()
        getQuote()
      },
      () => {
        setMessage('Some Error Occured, Please Try Again !')
        setSuccess(false)
        toastMessage()
      }
    )
  }

  const getPatchData = () => {
    let costUnit = product?.cost?.split(' ')

    let quotationData = {
      cost: costUnit
        ? values.cost + ' ' + (costUnit[1] ? costUnit[1] : 'USD') + ' ' + (costUnit[2] ? costUnit[2] : 'per-inch')
        : values.cost + ' USD per-inch',
      productionLeadTime: values.productionLeadTime,
      moq: values.moq,
      catalogueId: product.catalogueId,
      productId: product.productId,
      type: 'Buyer'
    }
    return quotationData
  }

  const toastMessage = () => {
    setOpen(true)
  }

  return (
    <div data-testid='product-quick-view'>
      {error ? (
        <div style={{ height: '35rem' }}>
          <Status
            datatestid='status-container-t'
            image={Images.ErrorOccured}
            message='Something Went Wrong!!!'
            info={error}
            error={error}
            height='35rem'
            imgheight='15rem'
            extraClasses='pt-5'
          />
        </div>
      ) : (
        <CRow>
          <CCol xs={12} md={6} lg={6} className='image-column'>
            {isLoading ? (
              <Skeleton variant='rectangular' height='50vh' />
            ) : (
              <div className='product-image'>
                {!props.slideShow ? (
                  <Magnifier src={selectedImage} width='100%' height='61vh' data-testid='magnifier' />
                ) : (
                  <div
                    style={{
                      position: 'relative',
                      height: '85vh',
                      width: '100%'
                    }}
                  >
                    <img
                      src={selectedImage}
                      style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '5px'
                      }}
                    />
                  </div>
                )}

                {!props.slideShow && (
                  <Carousel
                    images={product?.images}
                    handleLeftClick={handleLeftClick}
                    handleRightClick={handleRightClick}
                    carouselItemsRef={carouselItemsRef}
                    handleSelectedImageChange={handleSelectedImageChange}
                    selectedImageIndex={selectedImageIndex}
                  />
                )}
              </div>
            )}
          </CCol>

          <CCol xs={12} md={6} lg={6} className='card-box-container'>
            <div className='product-attributes-container'>
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='2em' />
              ) : (
                <>
                  <div className='pac-name'>
                    <Stack direction='row' spacing={1}>
                      {!!product.type && (
                        <Chip label={capitalizeFirstLetter(product.type)} color='primary' size='small' />
                      )}
                      {!!product.subCategory && (
                        <Chip label={capitalizeFirstLetter(product.subCategory)} color='success' size='small' />
                      )}
                    </Stack>
                    {capitalizeFirstLetter(product.name)}
                    {props.isUserView && !props.isReadOnly && (
                      <sup>
                        {isAuthorized(contextObj.userData.user.role, UserRoles.Admin) && props.needReview ? (
                          <Tooltip title='Approve Product'>
                            <IconButton aria-label='Approve' onClick={() => props.approveBtnClick(props.productId)}>
                              <BsCheckLg size='1rem' color='green' />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Edit Product'>
                            <IconButton aria-label='edit' onClick={() => navigate(`/products/${props.productId}`)}>
                              <AiTwotoneEdit size='1rem' color='#1976d2' />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isAuthorized(contextObj.userData.user.role, UserRoles.Admin) && props.needReview ? (
                          <Tooltip title='Reject Product'>
                            <IconButton aria-label='Reject' onClick={() => props.deleteProduct(props.productId)}>
                              <ImCross size='1rem' color='red' />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title='Delete Product'>
                            <IconButton aria-label='delete' onClick={() => props.deleteProduct(props.productId)}>
                              <AiOutlineDelete size='1rem' color='red' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </sup>
                    )}
                  </div>
                  {!!product.code && (
                    <div className='pac-code'>
                      <strong>Code:</strong> {product.code.toUpperCase()}
                    </div>
                  )}
                </>
              )}

              {isLoading ? (
                <Skeleton variant='rectangular' width='50%' height='1em' />
              ) : (
                <>
                  {!props.isUserView && !props.slideShow && (
                    <>
                      <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        spacing={3}
                        className='mt-1'
                      >
                        {props.eCatalogue ? (
                          <>
                            <Grid item>
                              <Button
                                variant='outlined'
                                size='small'
                                onClick={(e) => handleButtonClick(e)}
                                color={props.IsShortlistedProduct ? 'error' : 'success'}
                                style={{
                                  color: props.IsShortlistedProduct ? 'error' : 'success'
                                }}
                                startIcon={props.IsShortlistedProduct ? <FaBookmark /> : <FaRegBookmark />}
                              >
                                {props.IsShortlistedProduct ? <span>Remove</span> : <span>Shortlist</span>}
                              </Button>
                            </Grid>
                            {product?.quotationStatus !== 'Accepted' ? (
                              <Grid item>
                                <Tooltip
                                  title={
                                    props.isQuotationShared || product.quotationStatus === 'Quoted'
                                      ? ' Negotiate'
                                      : product.quotationStatus !== 'Requested'
                                        ? 'Request Quotation'
                                        : 'Quote Requested'
                                  }
                                >
                                  <span>
                                    <Button
                                      variant='outlined'
                                      size='small'
                                      color='primary'
                                      startIcon={<MdAttachMoney />}
                                      onClick={(e) => productQuotation(e)}
                                    >
                                      {props.isQuotationShared || product.quotationStatus === 'Quoted'
                                        ? 'Negotiate'
                                        : product.quotationStatus === 'Requested'
                                          ? 'Quote Requested'
                                          : 'Request Quote'}
                                    </Button>
                                  </span>
                                </Tooltip>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
              <hr />

              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {/* Size */}
                  {isNotNullUndefinedOrEmpty(product.dimention) && (isNotNullUndefinedOrEmpty(product.dimention[0]?.unitOfShape ) || typeof(product.dimention) == "string") && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Size</div>
                      <div className='col detail-info'>
                        <Chip
                          color='success'
                          variant='outlined'
                          label={
                            product.dimention.length
                              ? `${getDimensions(product.dimention[0]) || product.dimention} 
                              ${isNotNullUndefinedOrEmpty(product.dimention[0]?.unitOfShape)
                                ? product.dimention[0]?.unitOfShape?.toLowerCase()
                                : ''
                              }`
                              : product.dimention
                          }
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* Custom Attributes for Product */}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {!!product.customFields && isArray(product.customFields) && (
                    <>
                      {product.customFields.map((field, i) => {
                        return (
                          <div key={i} className='row product-attribute'>
                            <div className='col-4 detail-title'>{capitalizeFirstLetter(field.name)}</div>
                            <div className='col detail-info'>{capitalizeFirstLetter(field.value)}</div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </>
              )}

              {/* construction */}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {isNotNullUndefinedOrEmpty(product.construction) && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Construction</div>
                      <div className='col detail-info'>{capitalizeFirstLetter(product.construction)}</div>
                    </div>
                  )}
                </>
              )}

              {/* Materials */}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {isNotNullUndefinedOrEmpty(product.materials) &&
                    product.materials && (
                      <div className='row product-attribute'>
                        <div className='col-4 detail-title'>Materials</div>
                        <div className='col detail-info'>
                          {getMaterial(product.materials)}
                        </div>
                      </div>
                    )}
                </>
              )}

              {/* Weight */}
              {isLoading ? (
                <Skeleton variant='text' width='100%' height='3em' />
              ) : (
                <>
                  {checkValues('Weight', product) !== '' && (
                    <div className='row product-attribute'>
                      <div className='col-4 detail-title'>Weight</div>
                      <div className='col detail-info'>{checkValues('Weight', product)}</div>
                    </div>
                  )}
                </>
              )}

              {/* Quoted */}
              {product.quotationStatus === 'Quoted' ? (
                <>
                  {quotationLoading ? (
                    <>
                      <Skeleton variant='text' width='100%' height='3em' />
                      <Skeleton variant='text' width='100%' height='3em' />
                      <Skeleton variant='text' width='100%' height='3em' />
                    </>
                  ) : (
                    quotation.length > 0 && (
                      <>
                        <div className='row product-attribute'>
                          <div className='col-4 detail-title'>Cost</div>
                          <div className='col detail-info'>
                            {quotation[quotation.length - 1]?.type === 'Seller'
                              ? quotation[quotation.length - 1]?.cost
                              : quotation[quotation.length - 2]?.cost}
                          </div>
                        </div>

                        <div className='row product-attribute'>
                          <div className='col-4 detail-title'>Moq</div>
                          <div className='col detail-info'>
                            {quotation[quotation.length - 1]?.type === 'Seller'
                              ? quotation[quotation.length - 1]?.moq
                              : quotation[quotation.length - 2]?.moq}
                          </div>
                        </div>

                        <div className='row product-attribute'>
                          <div className='col-4 detail-title'>Production Lead Time</div>
                          <div className='col detail-info'>
                            {quotation[quotation.length - 1]?.type === 'Seller'
                              ? quotation[quotation.length - 1]?.productionLeadTime
                              : quotation[quotation.length - 2]?.productionLeadTime}
                          </div>
                        </div>
                      </>
                    )
                  )}
                </>
              ) : (
                <>
                  {/* Cost */}
                  {isLoading ? (
                    <Skeleton variant='text' width='100%' height='3em' />
                  ) : (
                    <>
                      {checkValues('cost', product) !== '' && (
                        <div className='row product-attribute'>
                          <div className='col-4 detail-title'>Cost</div>
                          <div className='col detail-info'>{checkValues('cost', product)}</div>
                        </div>
                      )}
                    </>
                  )}
                  {/* Minimum Order Quantity */}
                  {isLoading ? (
                    <Skeleton variant='text' width='100%' height='3em' />
                  ) : (
                    <>
                      {checkValues('moq', product) !== '' && (
                        <div className='row product-attribute'>
                          <div className='col-4 detail-title'>Minimum Order Quantity</div>
                          <div className='col detail-info'>{checkValues('moq', product)}</div>
                        </div>
                      )}
                    </>
                  )}

                  {/* Production Lead Time */}
                  {isLoading ? (
                    <Skeleton variant='text' width='100%' height='3em' />
                  ) : (
                    <>
                      {checkValues('productionLeadTime', product) !== '' && (
                        <div className='row product-attribute'>
                          <div className='col-4 detail-title'>Production Lead Time</div>
                          <div className='col detail-info'>{checkValues('productionLeadTime', product)}</div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </CCol>
        </CRow>
      )
      }
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth='md'
        title='Product Quotations'
        beta={true}
        content={
          <ProductQuotation
            product={product}
            updateCatalogueInfo={updateCatalogueInfo}
            handleChange={handleChange}
            values={values}
            quotation={quotation}
            acceptQuotation={acceptQuotation}
            handleClose={handleClose}
            open={open}
            success={success}
            message={message}
            index={props.index}
          />
        }
        handleClose={() => setIsOpen(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={success ? 'success' : 'warning'} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div >
  )
}

View.propTypes = {
  productId: PropTypes.string,
  setOpen: PropTypes.func,
  IsShortlistedProduct: PropTypes.bool,
  removeFromShortlist: PropTypes.func,
  addtoShortlist: PropTypes.func,
  Open: PropTypes.bool,
  isUserView: PropTypes.bool,
  id: PropTypes.string,
  deleteProduct: PropTypes.func,
  product: PropTypes.object,
  needReview: PropTypes.bool,
  approveBtnClick: PropTypes.func,
  index: PropTypes.number,
  slideShow: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isQuotationShared: PropTypes.bool,
  updateCatalogueInfo: PropTypes.func,
  key: PropTypes.number,
  catalogueData: PropTypes.array,
  fetchProducts: PropTypes.func,
  eCatalogue: PropTypes.bool,
  isNegotiated: PropTypes.bool,
  setIsNegotiated: PropTypes.func
}

export default View
