import React, { useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import { FiChevronsUp } from 'react-icons/fi'

import './GoTop.css'

const GoTop = () => {
  const { y: pageYOffset } = useWindowScroll()
  const [visible, setVisiblity] = useState(false)
  useEffect(() => {
    if (pageYOffset > 50) {
      setVisiblity(true)
    } else {
      setVisiblity(false)
    }
  }, [pageYOffset])
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

  if (!visible) {
    return false
  }
  return (
    <div
      className='scroll-to-top cursor-pointer text-center'
      onClick={scrollToTop}
      role='button'
      tabIndex='0'
      onKeyDown={(e) => {
        e.preventDefault()
      }}
    >
      <FiChevronsUp className='icon' color='#fff' size='1.5em' />
    </div>
  )
}

export default GoTop
