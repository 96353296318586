import React from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import PropTypes from 'prop-types'

import { convertToCamelCase, isNotNullUndefinedOrEmpty } from 'shared/util/helper'

const NestedSubitem = (props) => {
  return (
    <>
      {!props.isLoading && (
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='flex-start'
        >
          <div className='checkBoxes'>
            {Array.isArray(props.subItems) &&
            props.subItems.map((sub, idx) => (
              <Grid item key={idx} xs={12}>
                <FormControlLabel
                  className='checkBoxItem'
                  control={
                    <Checkbox
                      data-testid='checkBox'
                      size='small'
                      id={sub.id}
                      color='primary'
                      checked={sub.checked}
                      onChange={props.onChangeHandler}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9em' } }}
                    />
                }
                  label={
                    <span className='sub-item-label'>
                      {isNotNullUndefinedOrEmpty(sub.label)
                        ? `${convertToCamelCase(sub.label)} (${sub.count})`
                        : `Empty (${sub.count})`}
                    </span>
                }
                />
              </Grid>
            ))}
          </div>
        </Grid>
      )}
    </>
  )
}

NestedSubitem.propTypes = {
  subItems: PropTypes.array,
  isLoading: PropTypes.bool,
  onChangeHandler: PropTypes.func
}

export default NestedSubitem
