import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import './SlideShow.css'
import Dialog from 'components/uiElements/modal/Dialog'
import View from 'components/products/product/View'
import { Button } from '@mui/material'

const SlideShow = (props) => {
  const [index, setIndex] = useState(0)
  const delay = 6000
  const timeoutRef = useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }
  const handleNext = (e) =>{
    e.preventDefault()
    if((index<props.products.length-1)){
        setIndex(index+1)
    }
    else{
        setIndex(0)
    } 
}
  
  const handlePrevious = (e) =>{
    e.preventDefault()
    if(index > 0){
        setIndex(index-1)
    }
    else{
        setIndex(props.products.length-1)
    }
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === props.products.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    )

    return () => {
      resetTimeout()
    }
  }, [index])

  return (
      <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            <Dialog
                fullScreen={true}
                key={index}
                open={props.slideShow}
                fullWidth
                maxWidth='lg'
                content={
                    <div>
                <View
                    productId={props.products[index].id}
                    isUserView={false}
                    key={index}
                    isCustomerView={false}
                    product={props.products[index]}
                    slideShow={props.slideShow}
                />
                <div className='buttons'>
                <div className='next-btn'>
                <Button  variant='outlined' sx={{
                                border: '1px solid #ced4da',
                                height:'40px',
                                width:'130px'
                              }}
              onClick={handlePrevious}
              color='success'
            >
             &#171; Previous         
            </Button>
            </div>
            <div className='previous-btn'>
            <Button  variant='outlined' 
                    sx={{ border: '1px solid #ced4da',
                          height:'40px',
                          width:'130px'
                        }}
              onClick={handleNext}
              color='success'
            >
              Next  &#187;
            </Button>
            </div>
            </div>
                </div>
                }
                handleClose={() => props.setSlideShow(false)}
                isDialogReq
            />
        </div>
        </div>
  )
}

SlideShow.propTypes = {
  products: PropTypes.array,
  setSlideShow: PropTypes.func,
  slideShow: PropTypes.bool

}
export default SlideShow