import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { CBadge } from '@coreui/react'
import { isAuthorized } from 'shared/util/helper'
import { UserContext } from 'context/AuthContext/UserContext'

export const AppSidebarNav = ({ items }) => {
  const contextObj = React.useContext(UserContext)

  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className='ms-auto'>
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
          component: NavLink,
          activeclassname: 'active'
        })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  return (
    <>
      {items &&
        items.map((item, index) =>
          isAuthorized(contextObj.userData?.user?.role, item.role ?? contextObj.userData?.user?.role) &&
          isAuthorized(contextObj.userData?.user?.pricingPlan, item.pricing)
            ? navItem(item, index)
            : ''
        )}
    </>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired
}
