import React, { useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Checkbox,
  Fab,
  Input,
  Select,
  MenuItem,
  Switch,
  Grid,
  TextField
} from '@mui/material'
import { GrAdd } from 'react-icons/gr'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import HorizontalStatus from 'components/common/status/HorizontalStatus'
import CatalogueList from './Toolbars/CatalogueList'
import EnhancedTableHead from './TableHeader'
import BulkEdit from './Toolbars/BulkEdit'
import catalogueHelper from 'pages/catalogues/create/components/helper'

import Constants from 'data/Constants'

import './DataTable.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const DataTable = (props) => {
  const { searchKey, setSearchKey } = props
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('createdOn')
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => n.code)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, code) => {
    event.preventDefault()
    const selectedIndex = selected.indexOf(code)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getColumnData = (data, row, index) => {
    switch (data) {
      case 'images':
        return (
          <LazyLoadImage
            alt='product'
            src={row[data].length !== 0 ? row[data] : Constants.ImageFiller}
            height='50px'
            width='50px'
            effect='blur'
            placeholderSrc={Constants.ImageFiller}
            threshold={50}
          />
        )
      case 'Shape' || 'shape':
        return (
          <Select
            labelId='demo-select-small'
            id='demo-select-small'
            value={catalogueHelper.getShape(row) || 'Null'}
            label='Bulk'
            onChange={props.handleChange('shape', index)}
            size='small'
            sx={{ minWidth: 120 }}
            displayEmpty
          >
            <MenuItem value='Round'>Round</MenuItem>
            <MenuItem value='Rectangle'>Rectangle</MenuItem>
            <MenuItem value='Square'>Square</MenuItem>
            <MenuItem value='Triangle'>Triangle</MenuItem>
            <MenuItem value='Custom'>Custom</MenuItem>
          </Select>
        )
      case 'Size' || 'size':
        return (
          <Input
            onChange={props.handleChange('size', index)}
            style={{ width: '120px' }}
            type='text'
            value={catalogueHelper.getSize(row)}
          />
        )
      case 'Unit Of Shape' || 'unitOfShape':
        return (
          <Input
            style={{ width: '120px' }}
            onChange={props.handleChange('unitOfShape', index)}
            type='text'
            value={row.dimention[0]?.unitOfShape}
          />
        )
      case 'weight':
        return (
          <Input
            style={{ width: '120px' }}
            onChange={props.handleChange('weight', index)}
            type='number'
            value={row?.details?.length > 0 ? row.details[0].weight : row?.weight ? row?.weight?.split(' ')[0] : ''}
          />
        )
      case 'unitOfWeight':
        return (
          <Input
            style={{ width: '120px' }}
            onChange={props.handleChange('unitOfWeight', index)}
            type='text'
            value={
              row?.details?.length > 0
                ? row.details[0].unitOfWeight
                : row?.unitOfWeight
                ? row.unitOfWeight
                : row?.weight
                ? row?.weight?.split(' ')[1]
                : ''
            }
          />
        )
      case 'cost':
        return (
          <Input
            data-testid='cost'
            style={{ width: '120px' }}
            onChange={props.handleChange('cost', index)}
            type='number'
            value={row?.details?.length > 0 ? row.details[0]?.cost : row?.cost ? row?.cost.split(' ')[0] : ''}
          />
        )
      case 'currency':
        return (
          <Select
            labelId='demo-select-small'
            id='demo-select-small'
            value={
              row?.details?.length > 0
                ? row.details[0]?.currency
                : row?.currency
                ? row.currency
                : row?.cost
                ? row?.cost?.split(' ')[1]
                : ''
            }
            label='Currency'
            onChange={props.handleChange('currency', index)}
            size='small'
            sx={{ minWidth: 80 }}
            displayEmpty
          >
            <MenuItem value='USD'>USD</MenuItem>
            <MenuItem value='INR'>INR</MenuItem>
          </Select>
        )
      case 'unitPerCost':
        return (
          <Input
            data-testid='cost'
            style={{ width: '120px' }}
            onChange={props.handleChange('unitPerCost', index)}
            type='text'
            value={
              row?.details?.length > 0
                ? row.details[0]?.unitPerCost
                : row?.unitPerCost
                ? row.unitPerCost
                : row?.cost
                ? row?.cost.split(' ')[2]
                : ''
            }
          />
        )

      // Will work on material details as per the business needs

      // case 'Materials Name':
      //   return (
      //     <CFormInput
      //       data-testid='materials'
      //       style={{ width: '120px' }}
      //       onChange={props.handleChange('materials', index)}
      //       type='text'
      //       value={row.materials[0]?.name}
      //     />
      //   )
      // case 'percentage':
      //   return (
      //     <CFormInput
      //       data-testid='materials'
      //       style={{ width: '120px' }}
      //       onChange={props.handleChange('percentage', index)}
      //       type='number'
      //       value={row.materials[0]?.percentage}
      //     />
      //   )

      case 'dimention':
        return (
          <Input
            onChange={props.handleChange('dimention', index)}
            style={{ width: '120px' }}
            type='text'
            value={
              Array.isArray(row.dimention)
                ? catalogueHelper.getShape(row) +
                  ' ' +
                  catalogueHelper.getSize(row) +
                  ' ' +
                  (row.dimention[0]?.unitOfShape ? row.dimention[0].unitOfShape : '')
                : row.dimention
            }
          />
        )
      case 'materials':
        return (
          <Input
            data-testid='materials'
            style={{ width: '120px' }}
            onChange={props.handleChange('materials', index)}
            type='text'
            value={Array.isArray(row.materials) ? catalogueHelper.getMaterials(row) : row.materials}
          />
        )
      case 'code':
        return row[data]
      case 'name':
        return row[data]
      case 'moq':
        return (
          <Input
            style={{ width: '120px' }}
            onChange={props.handleChange('moq', index)}
            type='text'
            value={
              Array.isArray(row.details)
                ? row.details[0]?.moq !== 0
                  ? row.details[0]?.moq
                  : ''
                : row.moq
                ? row.moq
                : ''
            }
          />
        )
      case 'isRoundSizeAvail':
        return (
          <Switch
            id='demo-select-small'
            onChange={props.handleChange('isRoundSizeAvail', index)}
            checked={
              'details' in row
                ? row?.details[0]?.isRoundSizeAvail
                : row.isRoundSizeAvail !== '' && row.isRoundSizeAvail !== undefined
                ? row.isRoundSizeAvail
                : 'false'
            }
          />
        )
      case 'isFlamablityCertified':
        return (
          <Switch
            id='demo-select-small'
            onChange={props.handleChange('isFlamablityCertified', index)}
            checked={
              row.isFlamablityCertified !== '' && row.isFlamablityCertified !== undefined
                ? row.isFlamablityCertified
                : 'details' in row
                ? row?.details[0]?.isFlamablityCertified
                : false
            }
          />
        )
      case 'Sub Category':
        return (
          <Input
            style={{ width: '120px' }}
            type='text'
            onChange={props.handleChange('subCategory', index)}
            value={row.subCategory}
          />
        )
      case 'isExclusive' || 'Exclusive Product':
        return (
          <Switch
            id='demo-select-small'
            onChange={props.handleChange('isExclusive', index)}
            checked={row.isExclusive}
          />
        )
      case 'isNew' || 'New Product':
        return <Switch id='demo-select-small' onChange={props.handleChange('isNew', index)} checked={row.isNew} />
      case 'productionLeadTime':
        return (
          <Input
            style={{ width: '120px' }}
            onChange={props.handleChange('productionLeadTime', index)}
            type='number'
            value={row.productionLeadTime}
          />
        )
      case 'customFields':
        return (
          <>
            <Grid container spacing={1}>
              {row.customFields &&
                row.customFields.map((field, idx) => (
                  <Grid item key={idx}>
                    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                      <Grid item>
                        <TextField
                          size='small'
                          id={`Field-${idx + 1}`}
                          label={field.name}
                          variant='outlined'
                          value={field.value}
                          onChange={(e) => props.handleFieldChange(index, idx, 'value', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </>
        )
      default:
        return (
          <Input
            style={{ width: '120px' }}
            data-testid='typeInput'
            onChange={props.handleChange(data, index)}
            type='text'
            value={row[data] !== 0 ? row[data] : ''}
          />
        )
    }
  }

  const isSelected = (code) => selected.indexOf(code) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0

  const fabStyle = {
    position: 'absolute',
    bottom: 60,
    right: 16
  }

  const addButtonClick = (e) => {
    e.preventDefault()
    props.add()
  }

  return (
    <Box sx={{ width: '100%' }}>
      {props.title === 'Catalogues' && props.cataloguesCount > 0 && (
        <CatalogueList
          numSelected={selected.length}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          sortOn={props.sortOn}
          setSortOn={props.setSortOn}
          sortOrder={props.sortOrder}
          setSortOrder={props.setSortOrder}
          items={props.items}
          showReviewList={props.showReviewList}
          setDraftBtn={props.setDraftBtn}
          draftBtn={props.draftBtn}
        />
      )}
      {props.title === 'Edit Products' && <BulkEdit {...props} />}
      {props.rows.length > 0 && !props.isEmpty ? (
        <>
          <TableContainer
            className='catalogue-table-container table-width'
            sx={{
              '&::-webkit-scrollbar': {
                width: 5,
                height: 5
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'lightgrey'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'darkgrey',
                borderRadius: 2
              }
            }}
          >
            <Table aria-labelledby='tableTitle' stickyHeader={!!props.stickyHeader} sx={{ minWidth: '90%' }}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.rows.length}
                {...props}
              />

              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                {stableSort(props.rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => {
                    const isItemSelected = isSelected(row.code)
                    const labelId = `enhanced-table-checkbox-${rowIndex}`

                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        // onClick={(event) => {
                        //   !!props.showModal ? view(event, row.code) : handleClick(event, row.code)
                        // }}
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={rowIndex}
                        selected={isItemSelected}
                      >
                        {props.enableSelection && (
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                              onClick={(event) => handleClick(event, row.code)}
                            />
                          </TableCell>
                        )}

                        {props.headCells.map((headCell, index) => {
                          return (
                            <TableCell
                              className={`${headCell.isSticky ? 'sticky-column hover-pointer' : 'hover-pointer'}`}
                              component={index === 0 ? 'th' : ''}
                              id={index === 0 ? labelId : ''}
                              scope={index === 0 ? 'row' : ''}
                              align={headCell.label !== 'Action' ? (headCell.numeric ? 'right' : 'left') : 'center'}
                              key={index}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                            >
                              {props.title === 'Edit Products' || props.title === 'Edit Catalogue'
                                ? getColumnData(headCell.name, row, rowIndex)
                                : row[headCell.id]}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 55 * emptyRows
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {props.rows.length > 5 && (
            <TablePagination
              className='mt-5'
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={props.rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              data-testid='table-pagination'
            />
          )}
        </>
      ) : (
        <HorizontalStatus
          imagesrc={require('assets/svgs/shared/empty.svg').default}
          mainmsg={props.defaultMessage}
          smallmsg={props.defaulSubMessage}
          isAbsolute
        />
      )}
      {!!props.add && (
        <Fab color='primary' aria-label='add' sx={fabStyle} onClick={(e) => addButtonClick(e)}>
          <GrAdd size='2.5em' />
        </Fab>
      )}
    </Box>
  )
}

DataTable.propTypes = {
  searchKey: PropTypes.string,
  setSearchKey: PropTypes.func,
  setSortOn: PropTypes.func,
  rows: PropTypes.array,
  add: PropTypes.func,
  headCells: PropTypes.array,
  enableSelection: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  show: PropTypes.bool,
  title: PropTypes.string,
  handleChange: PropTypes.func,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  sortOn: PropTypes.string,
  items: PropTypes.array,
  isEmpty: PropTypes.bool,
  defaultMessage: PropTypes.string,
  defaulSubMessage: PropTypes.string,
  showReviewList: PropTypes.bool,
  setDraftBtn: PropTypes.func,
  draftBtn: PropTypes.bool,
  cataloguesCount: PropTypes.number,
  handleFieldChange: PropTypes.func
}

export default DataTable
