function getSize (row) {
  if (row?.dimention[0]?.shape === 1 || row?.dimention[0]?.shape === 'Round') {
    return row.dimention[0].diameter
  } else if (row?.dimention[0]?.shape === 2 || row?.dimention[0]?.shape === 'Rectangle') {
    return row.dimention[0].length + 'x' + row?.dimention[0].breadth
  } else if (row?.dimention[0]?.shape === 3 || row?.dimention[0]?.shape === 'Square') {
    return row.dimention[0].length + 'x' + row?.dimention[0].length
  } else if (row?.dimention[0]?.shape === 5 || row?.dimention[0]?.shape === 'Custom') {
    return row.dimention[0].custom
  } else {
    return ''
  }
}

function getCurrency (row) {
  if (row.details[0].unitOfCurrency === 1 || row.details[0].unitOfCurrency === 'USD') {
    return 'USD'
  } else if (row.details[0].unitOfCurrency === 2 || row.details[0].unitOfCurrency === 'INR') {
    return 'INR'
  } else {
    return ''
  }
}

function getShape (row) {
  if (row.dimention[0]?.shape === 1 || row.dimention[0]?.shape === 'Round') {
    return 'Round'
  } else if (row.dimention[0]?.shape === 2 || row.dimention[0]?.shape === 'Rectangle') {
    return 'Rectangle'
  } else if (row.dimention[0]?.shape === 3 || row.dimention[0]?.shape === 'Square') {
    return 'Square'
  } else if (row.dimention[0]?.shape === 4 || row.dimention[0]?.shape === 'Triangle') {
    return 'Triangle'
  } else if (row.dimention[0]?.shape === 5 || row.dimention[0]?.shape === 'Custom') {
    return 'Custom'
  } else {
    return ''
  }
}

function getMaterials (row) {
  let material = ''
  row.materials.forEach((item) => {
    material += item.name ? item.name + ':' + item.percentage + ' ' : ''
  })
  return material
}

const catalogueHelper = {
  getSize,
  getCurrency,
  getShape,
  getMaterials
}

export default catalogueHelper
