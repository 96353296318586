import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ToastMessage from 'components/uiElements/ToastMessage'
import { isNotNullUndefinedOrEmpty, isNotUndefinedAndNull } from 'shared/util/helper'
import catalogueServices from 'shared/services/catalogues.service'
import locationServices from 'shared/services/location-service'
import { catalogueActivity, createType } from 'data/Enums'
import productServices from 'shared/services/products.service'

export const useECatalogueHooks = (props) => {
  const { id, demoMode, shopName } = useParams()
  const isDemoMode = !!(isNotNullUndefinedOrEmpty(demoMode) && demoMode === 'demoMode')
  const [isLoading, setIsLoading] = useState(true)
  const [codes, setcodes] = useState([])
  const [products, setProducts] = useState([])
  const [sortedItems, setSortedItems] = useState([])
  const [company, setCompany] = useState()
  const [name, setName] = useState()
  const [pin, setPIN] = useState(null)
  const [userId, setUserId] = useState()
  const [validPIN, setValidPin] = useState(false)
  const [shortlisted, setShortlisted] = useState([])
  const [toast, addToast] = useState(0)
  const [open, setOpen] = useState(true)
  const [interestId, setInterestId] = useState('')
  const [error, setError] = useState('')
  const [messages, setMessages] = useState([])

  const [templateCode, setTemplateCode] = useState(0)
  const [values, setValues] = useState({
    email: '',
    fName: '',
    lName: '',
    pin: null,
    country: '',
    state: '',
    city: '',
    postal: '',
    latitude: '',
    longitude: '',
    iPv4: '',
    code: id
  })
  const [isQuotationShared, setIsQuotationShared] = useState(false)
  const toaster = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [currentStatus, setCurrentStatus] = useState()
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [selected, setSelected] = useState([])
  const [responseError, setResponseError] = useState(false)
  const [slideShow, setSlideShow] = useState(false)
  const [catalogueId, setCatalogueId] = useState()
  const [catalogueData, setCatalogueData] = useState()
  const [isNegotiated, setIsNegotiated] = useState('')
  const opened = []

  const toggleDrawer = (e) => {
    e.preventDefault()
    setFilterDrawer(!filterDrawer)
  }
  const fetchProducts = async () => {
    if (props.Ecatalogue) {
      await catalogueServices.getCatalogue(id).then(
        (response) => {
          setProducts(response.data.products)
          setSortedItems(response.data.products)
          setIsQuotationShared(response.data?.isQuotationShared)
          setName(response.data.name)
          setPIN(response.data.pin)
          setUserId(response.data.userId)
          setCatalogueId(response.data.id)
          setCatalogueData(response.data)
          setTemplateCode(response.data.templateCode)
          if (!isNotUndefinedAndNull(response.data.pin) || response.data.pin === 0) setValidPin(true)
          setCompany(response.data.company)
          setCurrentStatus(response.data.currentStatus)
          setIsLoading(false)
        },
        (err) => {
          setIsLoading(false)
          setError(err.response?.data)
          setResponseError(true)
        }
      )
    } else {
      await productServices.getProductOnShopName(shopName).then(
        (response) => {
          setProducts(response.data?.products)
          setSortedItems(response.data?.products)
          setName(response.data?.company.name)
          setCompany(response.data?.company)
          setIsLoading(false)
        },
        (err) => {
          setIsLoading(false)
          setError(err.response.data)
          setResponseError(true)
        }
      )
    }
  }

  const getLocation = async () => {
    await locationServices.getLocation().then(
      (response) => {
        setValues({
          ...values,
          country: response.data.country_name,
          state: response.data.state,
          city: response.data.city,
          postal: response.data.postal,
          latitude: response.data.latitude,
          longitude: response.data.longitude,
          iPv4: response.data.IPv4
        })
      },
      () => {}
    )
  }
  useEffect(() => {
    fetchProducts()
    if (!isDemoMode) {
      getLocation()
      window.addEventListener('beforeunload', function (e) {
        e.preventDefault()
        e.returnValue = 'Are you sure you want to test'
        // Handle session closure
        interestUpdate('endedOn', new Date())
        return e.returnValue
      })
    }
  }, [])

  const queryCreated = () => {
    if (currentStatus <= catalogueActivity.Queried) {
      updateCatalogueStatus(catalogueActivity.Queried)
    }
    const ids = shortlisted.map(function (item) {
      return item.productId
    })
    interestUpdate('queriedIds', ids)
  }
  const shortlistProduct = async (product) => {
    const temp = [...shortlisted]
    temp.push(product)
    const ids = temp.map((item) => item.productId)
    setShortlisted([...temp])
    addToast(toastMessage(product, true))
    interestUpdate('shortlistedIds', ids)
  }

  const removeProduct = async (product) => {
    const array = []
    shortlisted.forEach((element) => {
      if (element.productId !== product.productId) {
        array.push(element)
      }
    })
    setShortlisted([...array])
    const ids = array.map(function (item) {
      return item.productId
    })
    interestUpdate('shortlistedIds', ids)
    removeCode(product)
    addToast(toastMessage(product, false))
  }
  const handleClose = () => {
    createInterest()
    setOpen(false)
  }

  const handleSuccess = () => {
    // e.preventDefault()
    // eslint-disable-next-line eqeqeq
    if (!isNotUndefinedAndNull(pin) || pin === 0 || pin == values.pin) {
      setValidPin(true)
      setOpen(false)
      if (!isDemoMode) createInterest()
    } else {
      setError('Please Try Again with Correct PIN!!!')
    }
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  function createInterest() {
    const input = {
      buyer: {
        email: values.email,
        name: values.fName,
        createdBy: createType.Buyer,
        companyId: company.companyId
      },
      country: values.country,
      state: values.state,
      city: values.city,
      postal: values.postal,
      latitude: values.latitude,
      longitude: values.longitude,
      iPv4: values.iPv4,
      code: values.code,
      companyId: company.companyId
    }
    catalogueServices.generateInterest(input).then((response) => {
      setInterestId(response.data)
    })

    if (currentStatus <= catalogueActivity.Reviewed) {
      updateCatalogueStatus(catalogueActivity.Reviewed)
    }
  }

  const downloaded = async (isAllProducts = false) => {
    if (!isDemoMode && isNotNullUndefinedOrEmpty(interestId)) {
      const downloadedProducts = isAllProducts ? products : shortlisted
      const ids = downloadedProducts.map(function (item) {
        return item.productId
      })
      interestUpdate('downloadedIds', ids)
      updateCatalogueStatus(catalogueActivity.Downloaded)
    }
  }

  const openedProducts = (product) => {
    if (!opened.includes(product)) {
      opened.push(product)
      interestUpdate('openedIds', opened)
    }
  }

  const removeCode = (product) => {
    const array = [...codes]
    const index = array.indexOf(product.code)
    if (index !== -1) {
      array.splice(index, 1)
      setcodes([...array])
    }
  }

  const toastMessage = (product, isAdded = false) => {
    return (
      <ToastMessage
        color={isAdded ? 'success' : 'warning'}
        message={
          isAdded ? (
            <>
              Product <strong>{product.code}</strong> added to shortlist!
            </>
          ) : (
            <>
              Product <strong>{product.code}</strong> removed from shortlist!
            </>
          )
        }
      />
    )
  }

  const interestUpdate = async (key, newvalue) => {
    const changes = []
    const output = {
      value: newvalue,
      path: key,
      op: 'replace'
    }
    changes.push(output)
    await catalogueServices.updateInterest(interestId, changes).then(
      () => {},
      () => {}
    )
  }

  const updateCatalogueStatus = async (catalogueStatus) => {
    const output = {
      currentStatus: catalogueStatus
    }
    await catalogueServices.updateCatalogue(id, output).then(
      () => {},
      () => {}
    )
  }

  const updateCatalogueInfo = async (e, index) => {
    e.preventDefault()
    setError('')
    const input = []
    const productData = getPatchData(index)
    input.push(...productData)
    // setIsLoading(true)
    catalogueServices.updateCatalogueInfo(catalogueId, JSON.stringify(input)).then(
      () => {
        fetchProducts()
        setIsNegotiated('requested')
      },
      (error) => {
        setIsLoading(false)

        setError(!isNotNullUndefinedOrEmpty(error.data) ? error.data : 'Some Error Occured, Please Try Again')
      }
    )
  }

  const getPatchData = (index) => {
    const inp = []
    inp.push({
      path: `/product/${index}/quotationStatus`,
      op: 'add',
      value: 'Requested'
    })
    if (catalogueData?.requested?.length > 0) {
      let requested = [...catalogueData.requested]
      requested.push(catalogueData.products[index]?.productId)
      inp.push({
        path: `requested`,
        op: 'replace',
        value: requested
      })
    } else {
      let requested = []
      requested.push(catalogueData.products[index]?.productId)
      inp.push({
        path: `requested`,
        op: 'add',
        value: requested
      })
    }

    return inp
  }

  return {
    id,
    isDemoMode,
    isLoading,
    codes,
    products,
    sortedItems,
    setSortedItems,
    company,
    name,
    pin,
    userId,
    validPIN,
    shortlisted,
    toast,
    open,
    error,
    messages,
    setMessages,
    templateCode,
    isQuotationShared,
    toaster,
    isOpen,
    setIsOpen,
    filterDrawer,
    selected,
    setSelected,
    responseError,
    slideShow,
    values,
    setSlideShow,
    toggleDrawer,
    queryCreated,
    shortlistProduct,
    removeProduct,
    handleClose,
    handleSuccess,
    handleChange,
    downloaded,
    openedProducts,
    updateCatalogueInfo,
    catalogueData,
    fetchProducts,
    isNegotiated,
    setIsNegotiated
  }
}
