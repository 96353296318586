import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import PropTypes from 'prop-types'

import ChatWindow from './ChatWindow'
import launcherIcon from 'assets/chatbox/logo-no-bg.svg'

const Launcher = (props) => {
  const MessageCount = (props) => {
    if (props.count === 0 || props.isOpen === true) {
      return null
    }
    return <div className='sc-new-messages-count'>{props.count}</div>
  }

  const handleClick = () => {
    if (props.handleClick !== undefined) {
      props.handleClick()
    } else {
      props.setIsOpen(!props.isOpen)
    }
  }

  const isOpenModal = Object.prototype.hasOwnProperty.call(props, 'isOpen') ? props.isOpen : false
  const classList = ['sc-launcher', isOpenModal ? 'opened' : '']

  return (
    <div id='sc-launcher'>
      <div
        className={classList.join(' ')}
        onClick={handleClick}
        onKeyUp={handleClick}
        role='button'
        tabIndex={0}
      >
        <MessageCount count={props.newMessagesCount} isOpen={props.isOpen} />
        <AiOutlineClose className='sc-open-icon' color='#fff' size='2em' />
        <img alt='' className='sc-closed-icon' src={launcherIcon} />
      </div>

      <ChatWindow
        messageList={props.messageList}
        onUserInputSubmit={props.onMessageWasSent}
        agentProfile={props.agentProfile}
        isOpen={props.isOpen}
        onClose={handleClick}
        isDisabled={props.isDisabled}
      />
    </div>
  )
}

Launcher.propTypes = {
  onMessageWasReceived: PropTypes.func,
  onMessageWasSent: PropTypes.func,
  newMessagesCount: PropTypes.number,
  isOpen: PropTypes.bool,
  handleClick: PropTypes.func,
  messageList: PropTypes.arrayOf(PropTypes.object),
  mute: PropTypes.bool,
  count: PropTypes.number,
  agentProfile: PropTypes.object,
  setIsOpen: PropTypes.func,
  isDisabled: PropTypes.bool
}

Launcher.defaultProps = {
  newMessagesCount: 0
}

export default Launcher
