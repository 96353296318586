import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import PropTypes from 'prop-types'

const Header = (props) => {
  return (
    <div className='sc-header' data-testid='header'>
      <img className='sc-header--img' src={props.imageUrl} width='70' height='70' alt='' />
      <div className='sc-header--team-name'>{props.teamName}</div>
      <div
        className='sc-header--close-button'
        onClick={props.onClose}
        role='button'
        tabIndex={0}
        onKeyUp={props.onClose}
      >
        <AiOutlineClose color='#fff' size='1.5rem' />
      </div>
    </div>
  )
}

Header.propTypes = {
  imageUrl: PropTypes.string,
  teamName: PropTypes.string,
  onClose: PropTypes.func
}

export default Header
