import React from 'react'

const LoginPage = React.lazy(() => import('pages/Login'))
const RegisterPage = React.lazy(() => import('pages/Register'))
const PasswordResetPage = React.lazy(() => import('pages/Reset'))
const VerifyPage = React.lazy(() => import('pages/account/Verify'))
const Home = React.lazy(() => import('pages/Home'))
const Otp = React.lazy(() => import('pages/Otp'))

const routesList = [
  {
    path: '/login',
    name: 'Login',
    description: 'Discover the World of InnoSales',
    component: <LoginPage />,
    exact: true,
    checkLogin: true
  },
  {
    path: '/signup',
    name: 'Register',
    description: 'Start Your Free Trial of InnoSales',
    component: <RegisterPage />,
    exact: true,
    checkLogin: true
  },
  {
    path: '/reset',
    name: 'Reset Your Password',
    description: 'Forgot Your Password? Nothing to vary just Enter your E-Mail Id to Proceed',
    component: <PasswordResetPage />,
    exact: true,
    checkLogin: true
  },
  {
    path: '/verify/:token',
    name: 'E-Mail Verification',
    description: 'E-Mail Id Verification',
    component: <VerifyPage />,
    exact: true,
    checkLogin: false
  },
  {
    path: '/',
    title: 'InnoSales',
    description:
      'Create professional looking e-Catalogue to showcase your product inventory digitally, to track queries & activites, getting buyers and more',
    component: <Home />,
    exact: true,
    checkLogin: false
  },
  {
    path: '/otp',
    name: 'OTP Verify',
    description: 'OTP Verification',
    component: <Otp />,
    exact: true,
    checkLogin: false
  }
]

export default routesList
