import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './SortFilter.css'

import SortDropdown from 'components/uiElements/sort/SortDropdown'

const SortFilter = (props) => {
  const [sortOn, setSortOn] = useState(props.selectedFilter)
  const [sortOrder, setSortOrder] = useState(1)

  React.useEffect(() => {
    stateUpdate(sortOn, sortOrder)
  }, [sortOn, sortOrder])

  function compareValues(key, order) {
    return function innerSort(a, b) {
      if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
        return 0
      }
      const varA = key === 'createdOn' ? new Date(a[key]) : typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]
      const varB = key === 'createdOn' ? new Date(b[key]) : typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

      let comparison = 0
      if (varA > varB) {
        comparison = 1
      } else if (varA < varB) {
        comparison = -1
      }
      return order === -1 ? comparison * -1 : comparison
    }
  }

  const stateUpdate = (key, order = 'asc') => {
    props.setItems((prevState) => {
      const newState = [...prevState]
      return newState.sort(compareValues(key, order))
    })
  }

  return (
    <SortDropdown
      setSortOn={setSortOn}
      sortOn={sortOn}
      items={props.sortOnData}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
    />
  )
}

SortFilter.propTypes = {
  items: PropTypes.array,
  setItems: PropTypes.func,
  selectedFilter: PropTypes.string,
  sortOnData: PropTypes.array
}

export default SortFilter
