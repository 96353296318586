import axios from 'axios'
import { isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import authHeader from './auth-header'

const headers = {
  'Content-Type': 'application/json'
}

const signup = (userObj) => {
  return axios
    .post(`${process.env.REACT_APP_API}/signup`, userObj, { headers }, { timeout: process.env.REACT_APP_API_TIMEOUT })
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const payment = (paymentinfo) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/payment`,
      paymentinfo,
      { headers },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const authpayment = (authInfo) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/payment/auth`,
      authInfo,
      { headers },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getPaymentInfo = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/payment/info`,
      { headers: authHeader() },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const login = (email, pass) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/login`,
      {
        email,
        password: pass,
        applicationCode: 2
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        if (isNotNullUndefinedOrEmpty(response.data)) {
          localStorage.setItem('userId', JSON.stringify(response.data))
        }
        return response
      },
      (error) => {
        throw error.response
      }
    )
}
const verifyOtp = (userId, otp) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/verifyOtp`,
      {
        userId,
        otpNumber: otp,
        applicationCode: 2
      },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        if (isNotNullUndefinedOrEmpty(response.data.token)) {
          localStorage.setItem('iauser', JSON.stringify(response.data))
        }
        localStorage.removeItem('userId')
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const resendOtp = (userId) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/resendOtp`,
      { userId: userId },
      {
        timeout: process.env.REACT_APP_API_TIMEOUT
      }
    )
    .then(
      (response) => {
        if (isNotNullUndefinedOrEmpty(response.data.token)) {
          localStorage.setItem('iauser', JSON.stringify(response.data))
        }

        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const logout = () => {
  // ARUN - API CALL to expire token
  localStorage.removeItem('iauser')
}

const getCurrentUser = () => {
  const user = JSON.parse(localStorage.getItem('iauser'))
  if (isNotNullUndefinedOrEmpty(user)) {
    const currentDate = new Date().toISOString()
    if (Date.parse(user.tokenExpiration) > Date.parse(currentDate)) {
      return user
    } else {
      logout()
      return ''
    }
  }
}

const changePassword = (userCred) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/updatepassword`,
      userCred,
      { headers: authHeader() },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        if (isNotNullUndefinedOrEmpty(response.data.token)) {
          localStorage.setItem('iauser', JSON.stringify(response.data))
        }
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const updateEmail = (email) => {
  return !!isNotNullUndefinedOrEmpty(email)
}

const isLoggedIn = () => {
  return isNotNullUndefinedOrEmpty(getCurrentUser())
}

const getCompany = async () => {
  return axios.get(
    `${process.env.REACT_APP_API}/getusercompany`,
    { headers: authHeader() },
    {
      timeout: process.env.REACT_APP_API_TIMEOUT
    }
  )
}

const reset = async (email) => {
  return axios.get(`${process.env.REACT_APP_API}/reset/${email}`, {
    timeout: process.env.REACT_APP_API_TIMEOUT
  })
}

const updateProfile = async (profile) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API}/updateuser`,
      profile,
      { headers: authHeader('application/json; charset=UTF-8') },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const updateCompany = (request) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API}/updatecompany`,
      request,
      { headers: authHeader('application/json; charset=UTF-8') },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getProfile = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getuserprofile`,
      { headers: authHeader() },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getSettings = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/usersettings`,
      { headers: authHeader() },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const updateSettings = (request) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API}/usersettings`,
      request,
      { headers: authHeader('application/json; charset=UTF-8') },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getUsers = async () => {
  return axios
    .get(
      //`https://localhost:7092/User/users/2`,
      `${process.env.REACT_APP_API}/getcompanyusers/2`,
      { headers: authHeader() },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const getEmailVerificationToken = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_API}/getemailverifytoken`,
      { headers: authHeader() },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response.data
      },
      (error) => {
        throw error.response
      }
    )
}

const postEmailVerificationToken = (token) => {
  return axios
    .post(
      `${process.env.REACT_APP_API}/postemailverifytoken`,
      {
        token
      },
      { headers },
      { timeout: process.env.REACT_APP_API_TIMEOUT }
    )
    .then(
      (response) => {
        return response
      },
      (error) => {
        throw error.response
      }
    )
}

const authService = {
  signup,
  verifyOtp,
  resendOtp,
  login,
  logout,
  getCurrentUser,
  changePassword,
  updateEmail,
  isLoggedIn,
  payment,
  authpayment,
  getPaymentInfo,
  updateProfile,
  getCompany,
  reset,
  getProfile,
  updateCompany,
  getUsers,
  getSettings,
  updateSettings,
  getEmailVerificationToken,
  postEmailVerificationToken
}

export default authService
