import React from 'react'
import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'

import './HeaderBtn.css'

const HeaderBtn = (props) => {
  return (
    <Link to={props.toUrl} className='default-btn btn-bg-two border-radius-50'>
      {props.label}
    </Link>
  )
}

HeaderBtn.propTypes = {
  label: PropTypes.string,
  toUrl: PropTypes.string
}

export default HeaderBtn
