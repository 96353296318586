import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SendButton from './SendButton'

class UserInput extends Component {
  constructor () {
    super()
    this.state = {
      inputActive: false,
      inputHasText: false,
      emojiFilter: ''
    }
  }

  handleKeyDown (event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      return this._submitText(event)
    }
  }

  handleKeyUp (event) {
    const inputHasText = event.target.innerHTML.length !== 0 && event.target.innerText !== '\n'
    this.setState({ inputHasText })
  }

  _showFilePicker () {
    this._fileUploadButton.click()
  }

  _submitText (event) {
    event.preventDefault()
    const text = this.userInput.textContent

    if (!this.props.isDisabled && text && text.length > 0) {
      this.props.onSubmit({
        author: 'buyer',
        type: 'text',
        createdOn: new Date(),
        data: { text },
        unread: true
      })

      this.userInput.innerHTML = ''
    }
  }

  _renderSendOrFileIcon () {
    return (
      <div className='sc-user-input--button'>
        <SendButton onClick={this._submitText.bind(this)} />
      </div>
    )
  }

  render () {
    const { inputActive } = this.state
    return (
      <form className={`sc-user-input ${inputActive ? 'active' : ''}`}>
        <div
          role='button'
          tabIndex='0'
          onFocus={() => {
            this.setState({ inputActive: true })
          }}
          onBlur={() => {
            this.setState({ inputActive: false })
          }}
          ref={(e) => {
            this.userInput = e
          }}
          onKeyDown={this.handleKeyDown.bind(this)}
          onKeyUp={this.handleKeyUp.bind(this)}
          contentEditable='true'
          placeholder='Write a Query...'
          className='sc-user-input--text'
        />
        <div className='sc-user-input--buttons'>{this._renderSendOrFileIcon()}</div>
      </form>
    )
  }
}

UserInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
}

export default UserInput
