import React from 'react'
import { CNavItem, CNavTitle } from '@coreui/react'
import { MdOutlineDashboard, MdManageAccounts } from 'react-icons/md'
import { RiProductHuntLine, RiMessengerLine } from 'react-icons/ri'
import { BsCardChecklist } from 'react-icons/bs'
import { AiOutlineShop } from 'react-icons/ai'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <MdOutlineDashboard className='nav-icon' />,
    role: 5,
    pricing: 3
  },
  {
    component: CNavTitle,
    name: 'Manage',
    role: 2,
    pricing: 1
  },
  {
    component: CNavItem,
    name: 'Products',
    to: '/products',
    icon: <RiProductHuntLine className='nav-icon' />,
    role: 2,
    pricing: 1
  },
  {
    component: CNavItem,
    name: 'Catalogues',
    to: '/catalogues',
    icon: <BsCardChecklist className='nav-icon' />,
    role: 2,
    pricing: 2
  },
  {
    component: CNavItem,
    name: 'Messenger',
    to: '/messenger',
    icon: <RiMessengerLine className='nav-icon' />,
    role: 2,
    pricing: 2
  },
  {
    component: CNavTitle,
    name: 'Settings',
    role: 1,
    pricing: 1
  },
  {
    component: CNavItem,
    name: 'Account',
    to: '/account',
    icon: <MdManageAccounts className='nav-icon' />,
    role: 1,
    pricing: 1
  },
  {
    component: CNavItem,
    name: 'Company',
    to: '/company',
    icon: <AiOutlineShop className='nav-icon' />,
    role: 1,
    pricing: 1
  }
  // {
  //   component: CNavItem,
  //   name: 'Notifications',
  //   to: '/notifications',
  //   icon: <MdOutlineNotificationsActive className='nav-icon' />
  // }
]

export default _nav
