import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import dashboardRoutes from 'routes/DashboardRoutes'
import buyerRoutes from 'routes/BuyerRoutes'
import userRoutes from 'routes/UserRoutes'
import openRoutes from 'routes/OpenRoutes'
import Loader from 'components/common/Loader'

import authService from 'shared/services/auth.service'

import 'assets/scss/style.scss'
import { AuthContextProvider } from 'context/AuthContext/UserContext'
import ECatalogue from 'pages/ecatalogue/ECatalogue'

const DashboardLayout = React.lazy(() => import('layout/dashboard/DashboardLayout'))
const NormalLayout = React.lazy(() => import('layout/buyer/BuyerLayout'))
const UserLayout = React.lazy(() => import('layout/user/UserLayout'))

const PageNotFound = React.lazy(() => import('pages/PageNotFound'))

function App () {
  const navigate = useNavigate()
  const currentUser = authService.getCurrentUser()?.user
  const logOut = () => {
    authService.logout()
    navigate('/login', { state: 'You Have Been Successfully Logged out of the system' })
  }

  return (
    <React.Suspense fallback={<Loader />}>
      <AuthContextProvider>
        <Routes>
          {authService.isLoggedIn() && (
            <Route element={<DashboardLayout currentUser={currentUser} logOut={logOut} />}>
              {dashboardRoutes
                .filter((route) => currentUser?.role >= route.role && currentUser?.pricingPlan >= route.pricing)
                .map((route, idx) => {
                  return (
                    <Route
                      key={idx}
                      index={route.isIndex}
                      path={route.path}
                      name={route.name}
                      element={route.component}
                    />
                  )
                })}
            </Route>
          )}
          <Route element={<UserLayout currentUser={currentUser} loggedOutUser />}>
            {userRoutes.map((route, idx) => {
              return <Route key={idx} path={route.path} name={route.name} element={route.component} />
            })}
          </Route>

          <Route element={<NormalLayout />}>
            {buyerRoutes.map((route, idx) => {
              return <Route key={idx} path={route.path} name={route.name} element={route.component} />
            })}
          </Route>
          <Route element={<UserLayout openRoute />}>
            {openRoutes.map((route, idx) => {
              return <Route key={idx} path={route.path} name={route.name} element={route.component} />
            })}
          </Route>
          <Route path='shop/:shopName' element={<ECatalogue Ecatalogue={false} />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </AuthContextProvider>
    </React.Suspense>
  )
}

export default App
