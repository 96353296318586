import React, { useState, useEffect } from 'react'
import { Tooltip, TextField, Grid, FormControl, Button, Box, Typography } from '@mui/material'
import { AiFillShop } from 'react-icons/ai'
import PropTypes from 'prop-types'

import SortDropDown from '../../sort/SortDropdown'
import CustomisedInput from 'components/formElements/CustomisedInput'
import authService from 'shared/services/auth.service'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
const TableToolbar = (props) => {
  const [company, setCompany] = useState()
  const { setSearchKey } = props

  useEffect(() => {
    const getCompany = async () => {
      await authService.getCompany().then(
        (response) => {
          setCompany(response.data)
        },
        () => {}
      )
    }

    getCompany()
  }, [])
  const handleTabChange = () => {
    props.setDraftBtn(!props.draftBtn)
  }
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center' className='my-3'>
      <Grid item>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
          <Grid item>
            <Tooltip title='Search Catalogues'>
              <FormControl sx={{ m: 1, minWidth: 80 }} size='small' variant='outlined'>
                <TextField
                  label='Search on Name'
                  size='medium'
                  variant='standard'
                  input={<CustomisedInput />}
                  onChange={(e) => setSearchKey(e.target.value.toLowerCase())}
                />
              </FormControl>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={3}>
          {props.showReviewList && (
            <Grid item sx={{ marginTop: '1.2rem' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', typography: 'body1', bgcolor: 'background.paper' }}>
                <Tabs
                  value={props.draftBtn}
                  onChange={handleTabChange}
                  textColor='secondary'
                  indicatorColor='secondary'
                  aria-label='lab API tabs example'
                >
                  <Tab label='Active' disabled={!props.draftBtn} />
                  <Tab label='Drafted' disabled={props.draftBtn} />
                </Tabs>
              </Box>
              {/* <Button
                variant='outlined'
                size='large'
                onClick={() => props.setDraftBtn(!props.draftBtn)}
              >
                {props.draftBtn ? 'Active' : 'Draft'}
              </Button> */}
            </Grid>
          )}
          <Grid item>
            <SortDropDown
              setSortOn={props.setSortOn}
              sortOn={props.sortOn}
              sortOrder={props.sortOrder}
              setSortOrder={props.setSortOrder}
              items={props.items}
            />
          </Grid>
          <Grid item sx={{ marginTop: '1rem' }}>
            <Button
              variant='outlined'
              sx={{
                border: '1px solid #ced4da'
              }}
              onClick={() => window.open(`/shop/${company.shopName}`, '_blank')}
            >
              <AiFillShop size='2rem' />
              <Typography sx={{ display: { xs: 'none', sm: 'block' }}}>
                &nbsp; View Shop
              </Typography> 
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSearchKey: PropTypes.func,
  setSortOn: PropTypes.func,
  title: PropTypes.string,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  sortOn: PropTypes.string,
  items: PropTypes.array,
  showReviewList: PropTypes.bool,
  setDraftBtn: PropTypes.func,
  draftBtn: PropTypes.bool
}

export default TableToolbar
