import * as FileSaver from 'file-saver'
import jsPDF from 'jspdf'
import * as XLSX from 'xlsx'
const isNotNullUndefinedOrEmpty = (prop) => {
  return !!(prop !== undefined && prop !== null && prop !== '' && prop !== ' ')
}

const isNotUndefinedAndNull = (value) => {
  return !!(value !== undefined && value !== null)
}

const convertToCamelCase = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const IsTrue = (value) => {
  return !!(value !== undefined && value === true)
}

const isPercentageNotMoreThenValid = (data) => {
  let count = 0
  if (isNotUndefinedAndNull(data) && data.length > 0) {
    data.forEach((value) => {
      count += isNaN(value.percentage) ? 0 : parseInt(value.percentage)
    })
  }

  return !(count > 100)
}

const IsDecimal = (value) => {
  return isNotUndefinedAndNull(value) && value.toString().includes('.')
}

const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const getFormattedDate = (unformatedDate) => {
  const date = new Date(unformatedDate)
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : '0' + month

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return day + '-' + month + '-' + year
}

const getFormattedDayMonth = (unformatedDate) => {
  const weekday = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = new Date(unformatedDate)

  const month = date.getMonth()

  let day = date.getDate().toString()
  day = day.length > 1 ? day : '0' + day

  return weekday[month] + ' ' + day
}

function getFormatedAMPMTime(date) {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours || 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  const strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const getFromattedDateTime = (unformatedDateTime) => {
  const dateObj = new Date(unformatedDateTime)
  const date = getFormattedDate(dateObj)
  const time = getFormatedAMPMTime(dateObj)
  return date + ' ' + time
}

const getChatMessageDateTime = (unformatedDateTime) => {
  const dateObj = new Date(unformatedDateTime)
  const timeObj = getFormatedAMPMTime(dateObj)
  const date = getFormattedDayMonth(dateObj)
  return timeObj + ' | ' + date
}

const getCompleteUrl = (resourceUrl) => {
  return resourceUrl
}

const capitalizeFirstLetter = (sentence) => {
  if (typeof sentence !== 'string' || sentence.length === 0) {
    return sentence
  }

  return sentence
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const concatStringIfMore = (string, maxSize) => {
  return string.length > maxSize ? `${string.substring(0, maxSize - 3)}...` : string
}

const getExtension = (string) => {
  const lastDot = string.lastIndexOf('.')
  const subString = string.substring(lastDot + 1)
  const concatString = concatStringIfMore(string, 8)
  return concatString + '.' + subString
}

const getISTDateTime = () => {
  const currentTime = new Date()
  const currentOffset = currentTime.getTimezoneOffset()
  const ISTOffset = 330 // IST offset UTC +5:30
  return new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000)
}

const isArray = (obj) => {
  return isNotUndefinedAndNull(obj) && obj.length > 0
}

const isDateValid = (date) => {
  return isNotUndefinedAndNull(date) && date !== '0001-01-01T00:00:00' && date !== '1970-01-01T00:00:00Z'
}

const getFullName = (fName, lName) => {
  return isNotNullUndefinedOrEmpty(fName) && isNotNullUndefinedOrEmpty(lName)
    ? fName + ' ' + lName
    : isNotNullUndefinedOrEmpty(fName)
    ? fName
    : isNotNullUndefinedOrEmpty(lName)
    ? lName
    : ''
}

const isAuthorized = (userRole, allowedRole) => {
  return userRole >= allowedRole
}

const exportToCSV = (csvData, fileName, tableHead) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const wb = XLSX.utils.book_new()
  if (fileName == 'products') {
    csvData.forEach((element) => {
      element.images = element.images[0]
    })
    const ws = XLSX.utils.json_to_sheet(csvData)
    XLSX.utils.book_append_sheet(wb, ws, 'productsData')
  } else if (fileName != 'OverviewData') {
    const ws = XLSX.utils.json_to_sheet(csvData)
    XLSX.utils.sheet_add_aoa(ws, [['Month']], { origin: 'A1' })
    XLSX.utils.sheet_add_aoa(ws, [[tableHead]], { origin: 'B1' })
    XLSX.utils.book_append_sheet(wb, ws, 'data')
  } else {
    const tableHeads = [
      'Visited Catalogues',
      'Unique Catalogues',
      'Downloaded Catalogues',
      'New Users',
      'Queried Catalogues'
    ]
    const sheetNames = ['Visited', 'Unique', 'Downloaded', 'new users', 'Queried']
    csvData.forEach((field, idx) => {
      const ws = XLSX.utils.json_to_sheet(field)
      XLSX.utils.sheet_add_aoa(ws, [['Month']], { origin: 'A1' })
      XLSX.utils.sheet_add_aoa(ws, [[tableHeads[idx]]], { origin: 'B1' })
      XLSX.utils.book_append_sheet(wb, ws, sheetNames[idx])
    })
  }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

const addText = (slide, id) => {
  slide.addText(
    [
      {
        text: 'Visit e-Catalogue',
        options: {
          hyperlink: {
            url: `${window.location.origin}/ecatalogue/${id}`,
            tooltip: 'Visit Entire Catalog online @InnoSales'
          }
        }
      }
    ],
    {
      x: 0,
      y: 7.0,
      w: 6,
      h: 0.5,
      placeholder: 'cat-link',
      margin: 10,
      fontSize: 14,
      align: 'left',
      color: '6495ED'
    }
  )
}

const addProductImage = (slide, image, a, b, c, d) => {
  slide.addImage({ path: image, x: a, y: b, w: c, h: d })
}

const addProductId = (slide, id, a, b, c) => {
  slide.addText(`Product Id : ${id}`, {
    x: a,
    y: b,
    w: c,
    margin: 10,
    fontSize: 16,
    align: 'left',
    color: '000000'
  })
}

const pptProductTable = (product, slide, a, b, c, d) => {
  const arrTabRows = []
  for (const [key, value] of Object.entries(product)) {
    if (isNotNullUndefinedOrEmpty(value) && value !== 0) {
      switch (key) {
        case 'code':
          arrTabRows.push([
            {
              text: 'Product Code',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'name':
          arrTabRows.push([
            {
              text: 'Name',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'construction':
          arrTabRows.push([
            {
              text: 'Construction',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'subCategory':
          arrTabRows.push([
            {
              text: 'Sub Category',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: capitalizeFirstLetter(value),
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'productionLeadTime':
          arrTabRows.push([
            {
              text: 'Product Leadtime (days)',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            { text: value, options: { valign: 'top', align: 'left', fontFace: 'Courier' } }
          ])
          break
        case 'moq':
          arrTabRows.push([
            {
              text: 'MOQ',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'dimention':
          arrTabRows.push([
            {
              text: 'Dimention',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'weight':
          arrTabRows.push([
            {
              text: 'Weight',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'cost':
          arrTabRows.push([
            {
              text: 'FOB Cost',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
          break
        case 'materials':
          arrTabRows.push([
            {
              text: 'Material',
              options: { valign: 'top', align: 'left', fontFace: 'Arial', bold: true }
            },
            {
              text: value,
              options: { valign: 'top', align: 'left', fontFace: 'Courier' }
            }
          ])
      }
    }
  }
  slide.addTable(arrTabRows, {
    x: a,
    y: b,
    w: c,
    h: d,
    colW: [1.5, 1.5, 6],
    fill: { color: 'F7F7F7' },
    fontSize: 14,
    color: '363636',
    border: { pt: '1', color: 'BBCCDD' }
  })
}

const isObjectEmpty = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object
}

const checkValues = (type, value) => {
  if (type === 'cost') {
    if (
      isNotNullUndefinedOrEmpty(value?.details) &&
      isNotNullUndefinedOrEmpty(value?.details[0]) &&
      value.details[0].unitOfCurrency &&
      value.details[0].unitPerCost &&
      value.details[0].cost
    ) {
      return value.details[0].cost + ' ' + value.details[0].unitOfCurrency + ' ' + value.details[0].unitPerCost
    } else if (
      value?.details &&
      value.details.length > 0 &&
      isNotNullUndefinedOrEmpty(value?.details[0]?.cost)
    ) {
      const cost = value.details[0].cost
      const unitOfCurrency = isNotNullUndefinedOrEmpty(value?.details[0]?.unitOfCurrency)
        ? value.details[0].unitOfCurrency
        : ''
      return cost + ' ' + unitOfCurrency
    }
    else if(isNotNullUndefinedOrEmpty(value?.cost)){
      return value.cost
    }
     else {
      return ''
    }
  }
  if (type === 'dimention') {
    if (isNotNullUndefinedOrEmpty(value) && isNotNullUndefinedOrEmpty(value[0]) && value[0]?.unitOfShape) {
      if (value[0].shape === 'Rectangle' && value[0]?.length !== 0) {
        return value[0].length + 'x' + value[0].breadth + ' ' + value[0]?.unitOfShape
      } else if (value[0].shape === 'Square' && value[0]?.length !== 0) {
        return value[0].length + ' ' + value[0]?.unitOfShape
      } else if (value[0].shape === 'Round' && value[0]?.diameter !== 0) {
        return value[0].diameter + ' ' + value[0]?.unitOfShape
      } else if (value[0].shape === 'Custom' && value[0]?.custom !== 0) {
        return value[0].custom + ' ' + value[0]?.unitOfShape
      }
    } else if (isNotNullUndefinedOrEmpty(value) && !isNotNullUndefinedOrEmpty(value[0])) {
      return value
    } else {
      return ''
    }
  }
  if (type === 'Weight') {
    if (
      isNotNullUndefinedOrEmpty(value?.details) &&
      isNotNullUndefinedOrEmpty(value?.details[0]) &&
      value.details[0].weight &&
      value.details[0].unitOfWeight
    ) {
      return value.details[0].weight + ' ' + value.details[0].unitOfWeight
    } else if (isNotNullUndefinedOrEmpty(value?.weight)) {
      return value?.weight
    } else {
      return ''
    }
  }
  if (type === 'moq') {
    if (isNotNullUndefinedOrEmpty(value?.moq)) {
      return value?.moq
    } else {
      return ''
    }
  }
  if (type === 'productionLeadTime') {
    if (isNotNullUndefinedOrEmpty(value?.productionLeadTime) && value?.productionLeadTime !=0 ) {
      return value?.productionLeadTime
    } else {
      return ''
    }
  }
}

const generatePurchaseOrderPDF = (data) => {
  const doc = new jsPDF()

  // Set font size and color for header
  doc.setFontSize(12)
  doc.setTextColor(0, 0, 0)

  // Add Purchase Order details in the header
  doc.text('Purchase Order', 10, 10)
  doc.text(`Date: ${new Date().toLocaleDateString()}`, 150, 10)
  doc.text(`PO Number: PO-${Math.floor(Math.random() * 10000)}`, 10, 25)

  // Set font styles for table
  doc.setFont('normal')
  doc.setFontSize(10)

  // Add table headers
  const startY = 80 // Y coordinate for the first row
  const rowHeight = 10 // Height of each row
  const col1Width = 50 // Width of the first column (Name)
  const col2Width = 30 // Width of the second column (Cost)
  const col3Width = 40 // Width of the third column (Code)
  const col4Width = 20 // Width of the fourth column (MOQ)
  const col5Width = 60 // Width of the fifth column (Production Lead Time)
  doc.rect(5, startY + 2, col1Width + col2Width + col3Width + col4Width + col5Width, rowHeight * data.length, 'S')

  doc.text('Code', 15, startY)
  doc.text('Name', 55, startY)
  doc.text('Cost', 95, startY)
  doc.text('MOQ', 145, startY)
  doc.text('Production Lead Time', 170, startY)

  // Loop through the product data and add rows to the table
  data.forEach((product, index) => {
    const rowY = startY + (index + 1) * rowHeight
    doc.text(product.code, 15, rowY)
    doc.text(product.name, 55, rowY)
    doc.text(product.cost, 95, rowY)
    doc.text(product.moq, 145, rowY)
    doc.text(product.productionLeadTime, 180, rowY)
  })

  // Save the PDF
  doc.save('purchase_order.pdf')
}

export {
  isNotNullUndefinedOrEmpty,
  convertToCamelCase,
  IsTrue,
  isPercentageNotMoreThenValid,
  randomIntFromInterval,
  IsDecimal,
  getFromattedDateTime,
  getCompleteUrl,
  capitalizeFirstLetter,
  concatStringIfMore,
  getExtension,
  getChatMessageDateTime,
  isNotUndefinedAndNull,
  getISTDateTime,
  isArray,
  isDateValid,
  getFullName,
  isAuthorized,
  exportToCSV,
  addText,
  addProductImage,
  addProductId,
  pptProductTable,
  isObjectEmpty,
  checkValues,
  generatePurchaseOrderPDF,
  getFormattedDate
}
