import React from 'react'
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Tooltip, Chip } from '@mui/material'
import { MdClose } from 'react-icons/md'
import PropTypes from 'prop-types'

import './Modal.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Modal = (props) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth={props.fullWidth || false}
      maxWidth={props.maxWidth || 'xs'}
      open={props.open}
      onClose={props.handleClose}
      sx={{ margin: 'auto' }}
      disableEscapeKeyDown
    >
      {props.header !== undefined && (
        <DialogTitle>
          <Grid container direction='row' justifyContent='space-between' alignItems='flex-start'>
            <Grid item>
              <span className='modal-title'>
                {props.header}
                {props.beta && (
                  <Tooltip title=' This Feature is Under Development'>
                    <Chip label='Beta' color='success' variant='outlined' />
                  </Tooltip>
                )}
              </span>
            </Grid>
            <Grid item>
              <Button
                hidden={props.hidden}
                aria-label='close'
                onClick={props.handleClose}
                style={{
                  position: 'inherit',
                  float: 'right',
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <MdClose size='1.8em' />
              </Button>
            </Grid>
          </Grid>
          <hr />
        </DialogTitle>
      )}
      {props.close && (
        <Grid container direction='row' justifyContent='flex-end' alignItems='flex-start'>
          <Grid>
            <Button
              hidden={props.hidden}
              aria-label='close'
              onClick={props.handleClose}
              style={{
                position: 'inherit',
                float: 'right',
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <MdClose size='2.8rem' />
            </Button>
          </Grid>
        </Grid>
      )}
      <DialogContent>{props.content}</DialogContent>
      {(props.skipButton !== undefined || props.successButton !== undefined) && (
        <DialogActions>
          {props.skipButton} {props.successButton}
        </DialogActions>
      )}
    </Dialog>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.object,
  skipButton: PropTypes.object,
  successButton: PropTypes.object,
  fullWidth: PropTypes.bool,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.string,
  hidden: PropTypes.string,
  hideHeader: PropTypes.bool,
  close: PropTypes.bool,
  beta: PropTypes.bool
}

export default Modal
