import React from 'react'

const Dashboard = React.lazy(() => import('pages/Dashboard'))
const ProductsList = React.lazy(() => import('pages/products/view/ViewList'))
const BulkEdit = React.lazy(() => import('pages/products/view/components/BulkEdit'))
const AddProduct = React.lazy(() => import('pages/products/create'))

const History = React.lazy(() => import('pages/products/import/history'))
//const BulkMenu = React.lazy(() => import('pages/products/import/Menu'))
//const ExcelMenu = React.lazy(() => import('pages/products/import/excel/Menu'))

const CatalogueList = React.lazy(() => import('pages/catalogues/list/List'))
const CreateCatalogue = React.lazy(() => import('pages/catalogues/create'))
const CatalogueActivity = React.lazy(() => import('pages/catalogues/list/CatalogueActivity'))

const AccountMenu = React.lazy(() => import('pages/account/Menu'))
const Profile = React.lazy(() => import('pages/account/Profile'))
const Security = React.lazy(() => import('pages/account/Security'))

const Details = React.lazy(() => import('pages/account/company/Details'))
const ManageUsers = React.lazy(() => import('pages/account/company/ManageUsers'))
const PaymentMenu = React.lazy(() => import('pages/account/company/Subscription/PaymentMenu'))
const PaymentHistory = React.lazy(() => import('pages/account/company/Subscription/PaymentHistory'))
const Company = React.lazy(() => import('pages/account/company'))

// const Notifications = React.lazy(() => import('pages/Notifications'))
const Messages = React.lazy(() => import('components/chat/fullPage'))

const routesList = [
  { path: '/', isIndex: true, name: 'Products', component: <ProductsList />, exact: true, role: 2, pricing: 1 },
  { path: '/dashboard', name: 'Dashboard', component: <Dashboard />, exact: true, role: 5, pricing: 3 },
  {
    path: '/catalogues',
    name: 'Catalogues',
    component: <CatalogueList />,
    exact: true,
    role: 2,
    pricing: 2
  },
  { path: '/catalogues/view', name: 'All', component: <CatalogueList />, exact: true, role: 2, pricing: 2 },
  {
    path: '/catalogues/create',
    name: 'Create Catalogue',
    component: <CreateCatalogue />,
    exact: true,
    role: 3,
    pricing: 2
  },
  {
    path: '/catalogues/create/:id/:mode',
    name: 'Edit Catalogue',
    component: <CreateCatalogue />,
    exact: true,
    role: 3,
    pricing: 2
  },
  { path: '/products', name: 'Products', component: <ProductsList />, exact: true, role: 2, pricing: 1 },
  { path: '/products/view', name: 'All', component: <ProductsList />, exact: true, role: 2, pricing: 1 },
  { path: '/products/view/bulkedit', name: 'Edit', component: <BulkEdit />, exact: true, role: 4, pricing: 3 },
  {
    path: '/products/import',
    name: 'Import',
    component: <History />,
    exact: true,
    role: 4,
    pricing: 4
  },
  {
    path: '/products/add',
    name: 'Add',
    component: <AddProduct />,
    exact: true,
    role: 3,
    pricing: 1
  },
  {
    path: '/products/:id',
    name: 'Edit',
    component: <AddProduct />,
    exact: true,
    role: 3,
    pricing: 1
  },
  {
    path: '/catalogues/activity/:code/:name',
    name: 'Activity',
    component: <CatalogueActivity />,
    exact: true,
    role: 2,
    pricing: 3
  },
  {
    path: '/account',
    name: 'Account',
    component: <AccountMenu />,
    exact: true,
    role: 1,
    pricing: 1
  },
  {
    path: '/account/security',
    name: 'Security',
    component: <Security />,
    exact: true,
    role: 1,
    pricing: 1
  },
  {
    path: '/account/profile',
    name: 'Profile',
    component: <Profile />,
    exact: true,
    role: 1,
    pricing: 1
  },
  {
    path: '/company',
    name: 'Company',
    component: <Company />,
    exact: true,
    role: 4,
    pricing: 2
  },
  {
    path: '/company/payment',
    name: 'Payment',
    component: <PaymentMenu />,
    exact: true,
    role: 4,
    pricing: 2
  },
  {
    path: '/company/subscription',
    name: 'Subscription',
    component: <Company />,
    exact: true,
    role: 4,
    pricing: 2
  },
  {
    path: '/company/payment/history',
    name: 'Payment',
    component: <PaymentHistory />,
    exact: true,
    role: 4,
    pricing: 2
  },
  // {
  //   path: '/notifications',
  //   name: 'Notifications',
  //   component: Notifications,
  //   exact: true
  // },
  {
    path: '/messenger',
    name: 'Messanger',
    component: <Messages />,
    exact: true,
    role: 3,
    pricing: 2
  },
  {
    path: '/messenger/:catalogueId',
    name: 'Messanger',
    component: <Messages />,
    exact: true,
    role: 3,
    pricing: 2
  },
  {
    path: '/company/detail',
    name: 'Details',
    component: <Details />,
    exact: true,
    role: 4,
    pricing: 2
  },
  {
    path: '/company/users',
    name: 'Manage Users',
    component: <ManageUsers />,
    exact: true,
    role: 4,
    pricing: 2
  }
]

export default routesList
