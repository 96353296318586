import * as React from 'react'
import { MenuItem, Select, FormControl, FormHelperText, Tooltip, Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { FcAlphabeticalSortingAz, FcAlphabeticalSortingZa } from 'react-icons/fc'

import CustomisedInput from 'components/formElements/CustomisedInput'

const SortDropdown = (props) => {
  const handleChange = (event) => {
    props.setSortOn(event.target.value)
  }
  return (
    <Grid container direction='row' justifyContent='flex-end' alignItems='flex-end'>
        <Grid item>
          <FormControl sx={{ m: 1, minWidth: 80, ml:'0px' }}>
            <FormHelperText className='remove-margin'>Sort By</FormHelperText>
            <Select
              data-testid='select'
              id='catalogue-sort'
              value={props.sortOn}
              onChange={handleChange}
              defaultValue='createdOn'
              autoWidth
              label='Sort'
              inputProps={{ 'aria-label': 'Without label' }}
              input={<CustomisedInput />}
            >
              {props.items.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.name}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      <Tooltip
        title={props.sortOrder === 1 ? 'Sort in Descending Order' : 'Sort in Ascending Order'}
      >
        <Grid item sx={{ marginBottom: '0.6rem' }}>
          {props.sortOrder === 1
            ? (
              <Button
                variant='outlined'
                sx={{
                  border: '1px solid #ced4da'
                }}
                onClick={() => props.setSortOrder(-1)}
              >
                <FcAlphabeticalSortingAz size='2rem' />
              </Button>
              )
            : (
              <Button
                variant='outlined'
                sx={{
                  border: '1px solid #ced4da'
                }}
                onClick={() => props.setSortOrder(1)}
              >
                <FcAlphabeticalSortingZa size='2rem' />
              </Button>
              )}
        </Grid>
      </Tooltip>
    </Grid>
  )
}

SortDropdown.propTypes = {
  sortOn: PropTypes.string,
  setSortOn: PropTypes.func,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  items: PropTypes.array
}

export default SortDropdown
