import PropTypes from 'prop-types'
import React from 'react'
import MessageList from './messages/MessageList'
import UserInput from 'components/chat/chatBox/UserInput'
import Header from './Header'

const ChatWindow = (props) => {
  const onUserInputSubmit = (message) => {
    props.onUserInputSubmit(message)
  }

  const messageList = props.messageList || []
  const classList = ['sc-chat-window', props.isOpen ? 'opened' : 'closed']

  return (
    <div className={classList.join(' ')}>
      <Header
        teamName={props.agentProfile.teamName}
        imageUrl={props.agentProfile.imageUrl}
        onClose={props.onClose}
      />
      <MessageList messages={messageList} imageUrl={props.agentProfile.imageUrl} />
      <UserInput onSubmit={onUserInputSubmit} isDisabled={props.isDisabled} />
    </div>
  )
}

ChatWindow.propTypes = {
  agentProfile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUserInputSubmit: PropTypes.func.isRequired,
  messageList: PropTypes.array,
  isDisabled: PropTypes.bool
}

export default ChatWindow
