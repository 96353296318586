import pptxgen from 'pptxgenjs'
import Constants from 'data/Constants'
import { addProductId, addProductImage, addText, pptProductTable } from './helper'
import Images from 'data/Images'

export const GeneratePPT = (id, name, company, products, template) => {
  const pptx = new pptxgen()
  pptx.author = Constants.InnoSalesUrl
  pptx.company = Constants.InnoAge
  pptx.subject = 'PIS'
  pptx.title = company.name
  pptx.layout = 'LAYOUT_WIDE'

  // TITLE_SLIDE
  pptx.defineSlideMaster({
    title: 'WELCOME_SLIDE',
    objects: [
      {
        image: {
          x: 0.5,
          y: 0.5,
          w: 1.5,
          h: 1.5,
          path: company.logo
        }
      },
      {
        text: {
          text: company.name.length >= 30 ? `${company.name.substring(0, 30)}...` : company.name,
          options: {
            x: 7,
            y: 0.5,
            w: 6,
            h: 1,
            bold: true,
            valign: 'middle',
            align: 'center',
            fontSize: 30,
            fontFace: 'Calibri',
            fit: 'shrink'
          }
        }
      },
      {
        text: {
          text: name,
          options: {
            x: 7,
            y: 1.0,
            w: 6,
            h: 0.75,
            valign: 'middle',
            align: 'center',
            fontSize: 15,
            fontFace: 'Calibri',
            fit: 'shrink',
            color: '6495ED'
          }
        }
      },
      {
        image: {
          x: 0,
          y: 3,
          w: 13.3,
          h: 3.5,
          path: company.companyHeaders[0]
        }
      },
      {
        image: {
          x: 5,
          y: 7.1,
          w: 3,
          h: 0.3,
          valign: 'middle',
          align: 'center',
          path: Images.DesignedWithFooter
        }
      }
    ]
  })

  pptx.defineSlideMaster({
    title: 'PRODUCT_SLIDE6',
    background: { color: 'FFFFFF' },
    objects: [
      {
        image: {
          x: 0.2,
          y: 0.2,
          w: 1.1,
          h: 1.1,
          path: company.logo
        }
      },
      {
        text: {
          text: company.name.length >= 30 ? `${company.name.substring(0, 30)}...` : company.name,
          options: {
            x: 6,
            y: 0,
            w: 6,
            h: 0.75,
            bold: true,
            valign: 'middle',
            align: 'center',
            fontSize: 30,
            fontFace: 'Arial',
            fit: 'shrink'
          }
        }
      }
    ],
    slideNumber: { x: '95%', y: '95%', fontFace: 'Courier', color: '6495ED' }
  })

  const welcome = pptx.addSlide({ masterName: 'WELCOME_SLIDE' })
  welcome.addText(
    [
      {
        text: 'Visit e-Catalogue',
        options: {
          hyperlink: {
            url: `${window.location.origin}/ecatalogue/${id}`,
            tooltip: 'Visit Entire Catalog online @InnoSales'
          }
        }
      }
    ],
    {
      x: 7,
      y: 1.5,
      w: 6,
      h: 0.5,
      placeholder: 'cat-link',
      margin: 10,
      fontSize: 14,
      align: 'center'
    }
  )

  // Add a Slide
  let masterSlide = 'PRODUCT_SLIDE6'
  if (template === 3 || template ===  4 ||template ===  5) {
    products.map((product) => {
      const slide = pptx.addSlide({ masterName: masterSlide })
      if (product.images.length >= 4) {
        addProductImage(slide, product.images[0], 0.2, 1.5, 6.5, 5.2)
        addProductImage(slide, product.images[1], 6.8, 4.4, 2, 2.2)
        addProductImage(slide, product.images[2], 9.0, 4.4, 2, 2.2)
        addProductImage(slide, product.images[3], 11.2, 4.4, 2, 2.2)
        pptProductTable(product, slide, 6.8, 1.3, 5.0, 0.2)
        slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
        addText(slide, id)
      } else if (product.images.length === 3) {
        addProductImage(slide, product.images[0], 0.2, 1.5, 6.5, 5.2)
        addProductImage(slide, product.images[1], 6.8, 4.4, 2, 2.2)
        addProductImage(slide, product.images[2], 9.0, 4.4, 2, 2.2)
        pptProductTable(product, slide, 6.8, 1.3, 5.0, 0.2)
        slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
        addText(slide, id)
      } else if (product.images.length === 2) {
        addProductImage(slide, product.images[0], 0.2, 1.5, 6.5, 5.2)
        addProductImage(slide, product.images[1], 6.8, 4.4, 2, 2.2)
        pptProductTable(product, slide, 6.8, 1.3, 5.0, 0.2)
        slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
        addText(slide, id)
      } else {
        addProductImage(slide, product.images[0], 0.2, 1.5, 6.5, 5.2)
        pptProductTable(product, slide, 6.8, 1.3, 5.0, 0.2)
        slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
        addText(slide, id)
      }
      return slide
    })
  } else {
    if (template === 1) {
      products.map((product) => {
        const slide = pptx.addSlide({ masterName: masterSlide })
        slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
        pptProductTable(product, slide, 2.4, 1.5, 7.0, 0.2, 0)
      })
    } else if (template === 2) {
      products.map((product) => {
        const slide = pptx.addSlide({ masterName: masterSlide })
        slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
        addProductImage(slide, product.images[0], 0.2, 1.5, 6.5, 5.2)
        pptProductTable(product, slide, 6.8, 1.5, 6.5, 0.2)
      })
    } else if (template === 6) {
      let slide
      products.map((product, index) => {
        let value = index + 1
        if (value % 2 === 1) {
          slide = pptx.addSlide({ masterName: masterSlide })
          slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
          addProductImage(slide, product.images[0], 0.2, 1.5, 6.5, 4.2)
          addProductId(slide, id, 0.8, 6.0, 5.5)
        } else if (value % 2 === 0) {
          addProductImage(slide, product.images[0], 6.8, 1.5, 6.5, 4.2)
          addProductId(slide, id, 6.8, 6.0, 5.5)
        }
      })
    } else if (template === 7) {
      let slide
      products.map((product, index) => {
        let value = index + 1
        if (value % 3 === 1) {
          slide = pptx.addSlide({ masterName: masterSlide })
          slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
          addProductImage(slide, product.images[0], 0.2, 1.5, 6.3, 4.2)
          addProductId(slide, id, 0.8, 6.0, 5.5)
        } else if (value % 3 === 2) {
          addProductImage(slide, product.images[0], 6.8, 1.1, 6.3, 2.5)
          addProductId(slide, id, 7.0, 3.9, 3.0)
        } else if (value % 3 === 0) {
          addProductImage(slide, product.images[0], 6.8, 4.3, 6.3, 2.5)
          addProductId(slide, id, 7.0, 7.1, 5.5)
        }
      })
    } else if (template === 8) {
      let slide
      products.map((product, index) => {
        let value = index + 1
        if (value % 4 === 1) {
          slide = pptx.addSlide({ masterName: masterSlide })
          slide.addImage({ path: Images.DesignedWithFooter, x: 5.0, y: 7.1, w: 3, h: 0.3, align: 'center' })
          addProductImage(slide, product.images[0], 0.3, 1.5, 5.4, 2.4)
          addProductId(slide, id, 0.4, 4.1, 3.0)
        } else if (value % 4 === 2) {
          addProductImage(slide, product.images[0], 6.1, 1.5, 5.4, 2.4)
          addProductId(slide, id, 6.3, 4.1, 3.0)
        } else if (value % 4 === 3) {
          addProductImage(slide, product.images[0], 0.3, 4.3, 5.4, 2.4)
          addProductId(slide, id, 0.4, 6.8, 3.0)
        } else if (value % 4 === 0) {
          addProductImage(slide, product.images[0], 6.1, 4.3, 5.4, 2.4)
          addProductId(slide, id, 6.3, 6.8, 3.0)
        }
      })
    }
  }

  // 4. Save the Presentation
  pptx.writeFile({ fileName: 'Presentation.pptx' })
}
