import React from 'react'
import PropTypes from 'prop-types'

import './CHeaderBrands.css'
import { concatStringIfMore, isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import Avatar from 'components/uiElements/Avatar'

const CHeaderBrands = (props) => {
  return (
    <div className='row' data-testid='t-header'>
      <div className='col-sm-3 logo-img'>
        {isNotNullUndefinedOrEmpty(props.company?.logo)
          ? (
            <img src={props.company.logo} alt='Logo' height={50} width={50} style={{ padding: 2, marginRight: 20 }} />
            )
          : (
            <Avatar name={props.company?.name} height={45} width={45} />
            )}
      </div>
      <div className='col d-none d-md-block'>
        <div className='row company-name-header'>
          {isNotNullUndefinedOrEmpty(props.company?.name)
            ? concatStringIfMore(props.company?.name, 20)
            : 'Your Company Name'}
        </div>
        <div className='row catalogue-name-header'>
          {isNotNullUndefinedOrEmpty(props.company?.name) ? concatStringIfMore(props.name, 20) : 'Catalogue Name'}
        </div>
      </div>
    </div>
  )
}

CHeaderBrands.propTypes = {
  company: PropTypes.object,
  name: PropTypes.string
}

export default CHeaderBrands
