import React from 'react'
import { Grid, Button } from '@mui/material'
import { FcAddRow } from 'react-icons/fc'
import PropTypes from 'prop-types'

const BulkEdit = (props) => {
  return (
    <Grid container direction='row' justifyContent='flex-end' alignItems='center' className='my-3'>
      <Grid item>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={3}>
          <Grid item>
            <Button
              variant='outlined'
              sx={{
                border: '1px solid #ced4da'
              }}
              onClick={props.handleAddMore}
            >
              <FcAddRow size='2rem' /> &nbsp; Add More
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              sx={{
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem'
              }}
              onClick={props.handleProductSave}
            >
              Update Products
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

BulkEdit.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setSearchKey: PropTypes.func,
  setSortOn: PropTypes.func,
  title: PropTypes.string,
  setSortOrder: PropTypes.func,
  sortOrder: PropTypes.number,
  sortOn: PropTypes.string,
  items: PropTypes.array,
  handleAddMore: PropTypes.func,
  handleProductSave: PropTypes.func
}

export default BulkEdit
