import React from 'react'

const ECatalogue = React.lazy(() => import('pages/ecatalogue/ECatalogue'))
const ServerError = React.lazy(() => import('pages/ServerError'))

const routesList = [
  { path: '/ecatalogue/:id', name: 'Catalogue', component: <ECatalogue />, exact: true },
  { path: '/ecatalogue/:id/:demoMode', name: 'Demo Page', component: <ECatalogue />, exact: true },
  { path: '/Error', name: 'Error', component: <ServerError />, exact: true }
]

export default routesList
