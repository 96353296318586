const getProductStatus = (status) => {
  switch (status) {
    case 1:
      return 'Draft'
    case 2:
      return 'Active'
    case 3:
      return 'Inactive'
    default:
      return ''
  }
}

const UserRoles = {
  Dummy: 1,
  Read: 2,
  Write: 3,
  Admin: 4,
  SuperAdmin: 5
}

const pricingPlan = {
  Freemium: 1,
  Standard: 2,
  Premium: 3,
  Elite: 4
}

const roles = ['Invalid', 'Dummy', 'Read', 'Write', 'Admin', 'Super Admin']

const getAccessCodeDetails = (code) => {
  switch (code) {
    default:
      return {
        value: 1,
        value: roles[0],
        detail: 'User Subscription has ended please renew subscription'
      }
    case 5:
      return { value: 5, label: roles[5], detail: 'User has unrestricted access to system' }
    case 4:
      return {
        value: 4,
        label: roles[4],
        detail: 'User has unrestricted access to sytem but cannot Manage Users'
      }
    case 3:
      return {
        value: 3,
        label: roles[3],
        detail: 'User Can Create Products & Proposal but need admin approval to publish'
      }
    case 2:
      return {
        value: 2,
        label: roles[2],
        detail: 'User has read access to the system data'
      }
    case 1:
      return {
        value: 1,
        label: roles[1],
        detail: 'User has temporary read system to the system'
      }
  }
}

const getAllAccessCodes = () => {
  const accessCodes = []
  for (let index = 1; index < 6; index++) {
    accessCodes.push(getAccessCodeDetails(index))
  }
  return accessCodes
}

const getMonth = (month) => {
  switch (month) {
    case 1:
      return 'January'
    case 2:
      return 'Febrauray'
    case 3:
      return 'March'
    case 4:
      return 'April'
    case 5:
      return 'May'
    case 6:
      return 'June'
    case 7:
      return 'July'
    case 8:
      return 'August'
    case 9:
      return 'September'
    case 10:
      return 'October'
    case 11:
      return 'November'
    case 12:
      return 'December'
  }
}

const catalogueActivity = {
  None: 0,
  UnReviewed: 1,
  Reviewed: 2,
  Queried: 3,
  Shortlisted: 4,
  Downloaded: 5
}

const getCatalogueActivity = (activity) => {
  switch (activity) {
    case 1:
      return 'UnReviewed'
    case 2:
      return 'Reviewed'
    case 3:
      return 'Queried'
    case 4:
      return 'Shortlisted'
    case 5:
      return 'Downloaded'
    default:
      return 'None'
  }
}

const catalogueStatus = {
  None: 0,
  Draft: 1,
  Active: 2,
  Deleted: 3,
  Expired: 4
}

const getCatalogueStatus = (status) => {
  switch (status) {
    case 1:
      return 'Draft'
    case 2:
      return 'Active'
    case 3:
      return 'Deleted'
    case 4:
      return 'Expired'
    default:
      return 'None'
  }
}

const getBulkStatus = (status) => {
  switch (status) {
    case true:
      return 'Sucess'
    case false:
      return 'Failed'
  }
}

const createType = {
  Other: 0,
  Self: 1,
  Seller: 2,
  Buyer: 3
}

const productShape = {
  Round: 1,
  Rectangle: 2,
  Square: 3,
  Custom: 4
}

const getProductShape = (status) => {
  switch (status) {
    case 1:
      return 'Round'
    case 2:
      return 'Rectangle'
    case 3:
      return 'Square'
    case 4:
      return 'Custom'
    default:
      return 'None'
  }
}

export {
  getProductStatus,
  getCatalogueStatus,
  getAccessCodeDetails,
  getAllAccessCodes,
  getMonth,
  getCatalogueActivity,
  getProductShape,
  getBulkStatus,
  createType,
  UserRoles,
  catalogueActivity,
  catalogueStatus,
  productShape,
  pricingPlan
}
