import React, { useState } from 'react'
import { CSidebar, CSidebarNav, CSidebarBrand, CSidebarFooter } from '@coreui/react'
import { BiLogOut } from 'react-icons/bi'
import SimpleBar from 'simplebar-react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { AppSidebarNav } from './AppSidebarNav'
import navigation from 'routes/SidebarNav'

import Icon from 'assets/brand/logo_small.svg'

import 'simplebar/dist/simplebar.min.css'

const AppSidebar = (props) => {
  const [narrowShow, setNarrowShow] = useState(true)

  const navigate = useNavigate()

  const handleLogoutClick = (e) => {
    e.preventDefault()
    props.logOut()
    return navigate('/login')
  }

  return (
    <CSidebar
      position='fixed'
      visible={props.show}
      narrow={narrowShow}
      // onVisibleChange={(visible) => {
      //   props.setShow(visible)
      // }}
      onMouseEnter={(e) => {
        e.preventDefault()
        setNarrowShow(false)
      }}
      onMouseLeave={(e) => {
        e.preventDefault()
        setNarrowShow(true)
      }}
    >
      <CSidebarBrand className='d-none d-md-flex' to='/'>
        <img
          src={require('assets/brand/logo_color.svg').default}
          height={50}
          alt='InnoSales'
          className='sidebar-brand-full'
        />
        <img src={Icon} height={50} alt='InnoSales' className='sidebar-brand-narrow' />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarFooter className='get-hover-effect' onClick={(e) => handleLogoutClick(e)}>
        <BiLogOut className='nav-icon' /> &nbsp; Logout
      </CSidebarFooter>
    </CSidebar>
  )
}

AppSidebar.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  logOut: PropTypes.func
}

export default React.memo(AppSidebar)
