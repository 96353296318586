/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { List, Grid } from '@mui/material'
import PropTypes from 'prop-types'

import NestedItem from './NestedItem'
import { convertToCamelCase, isNotNullUndefinedOrEmpty } from 'shared/util/helper'
import NestedSubitem from './NestedSubitem'

import './Filter.css'

const seprator = '#'

const Filter = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [initialItems, setInitialItems] = useState([])
  const [items, setItems] = useState([])

  useEffect(() => {
    const tempItems = []
    props.filters.forEach((filter) => {
      props.data.forEach((item, index) => {
        const itemIndex = tempItems.map((a) => a.name).indexOf(filter.name)
        if (itemIndex === -1) {
          tempItems.push({
            id: `${filter.name}`,
            name: filter.name,
            label: filter.label,
            subItems: [
              {
                id: `${filter.name}${seprator}${index}`,
                parent: filter.name,
                label: isNotNullUndefinedOrEmpty(item[filter.name])
                  ? item[filter.name].toLowerCase()
                  : '',
                checked: false,
                count: 1
              }
            ]
          })
        } else {
          const subItemIndex = tempItems[itemIndex].subItems
            .map((a) => a.label)
            .indexOf(
              isNotNullUndefinedOrEmpty(item[filter.name]) ? item[filter.name].toLowerCase() : ''
            )

          if (subItemIndex === -1) {
            const tempSubItem = {
              id: `${filter.name}${seprator}${index}`,
              parent: filter.name,
              label: isNotNullUndefinedOrEmpty(item[filter.name])
                ? item[filter.name].toLowerCase()
                : '',
              checked: false,
              count: 1
            }
            tempItems[itemIndex].subItems.push(tempSubItem)
          } else {
            tempItems[itemIndex].subItems[subItemIndex].count++
          }
        }
      })
    })
    setItems([...tempItems])
    setInitialItems([...tempItems])
    setIsLoading(false)
  }, [props.data])

  useEffect(() => {
    const array = props.data
    const filteredItems = []
    items.forEach((item) => {
      item.subItems.forEach((subItem) => {
        if (subItem.checked === true) {
          const temp = filterItems(array, subItem.parent, subItem.label)

          if (temp.length > 0) {
            temp.forEach((item) => {
              if (filteredItems.indexOf(item) === -1) filteredItems.push(item)
            })
          }
        }
      })
    })

    const isChecked = items.map((a) => a.checked).indexOf(true)

    if (filteredItems.length > 0) {
      props.setFilteredData(filteredItems)
    } else if (isChecked === -1) {
      props.setFilteredData(props.data)
    } else {
      props.setFilteredData([])
    }
  }, [items])

  const filterItems = (arr, field, value) => {
    if (field != null) {
      return arr.filter((item) => {
        if (isNotNullUndefinedOrEmpty(value)) {
          return item[field]?.toLowerCase() === value?.toLowerCase()
        } else {
          return !isNotNullUndefinedOrEmpty(item[field])
        }
      })
    }
  }

  const onChangeHandler = (e) => {
    const subItemsList = items
    const selectedIndex = e.target.id
    const selectedItem = selectedIndex.substr(0, selectedIndex.indexOf(seprator))
    const index = subItemsList.map((a) => a.id).indexOf(selectedItem)

    if (index !== -1) {
      const itemClicked = subItemsList[index]
      const subItemClicked = subItemsList[index].subItems.map((a) => a.id).indexOf(selectedIndex)
      subItemsList[index].subItems[subItemClicked].checked =
        !itemClicked.subItems[subItemClicked].checked
      setItems([...subItemsList])
      const tempSelected = props.selected
      if (subItemsList[index].subItems[subItemClicked].checked &&
        !tempSelected.includes(subItemsList[index].subItems[subItemClicked].id)) {
        props.setSelected([...props.selected, subItemsList[index].subItems[subItemClicked].id])
      } else if (!subItemsList[index].subItems[subItemClicked].checked &&
               tempSelected.includes(subItemsList[index].subItems[subItemClicked].id)) {
        const removeIndex = tempSelected.indexOf(subItemsList[index].subItems[subItemClicked].id)
        tempSelected.splice(removeIndex, 1)
        props.setSelected([...tempSelected])
      }
    }
  }

  const handleSearchOnSubFilter = (e, name) => {
    const typedSubWord = e.target.value.toLowerCase()

    const tempItems = JSON.parse(JSON.stringify(initialItems))

    if (isNotNullUndefinedOrEmpty(typedSubWord)) {
      tempItems
        .filter((x) => x.name === name)
        .map((subItem) => {
          const x = []
          subItem.subItems.map((subItemElement) => {
            if (subItemElement.label.toLowerCase().includes(typedSubWord)) {
              x.push(subItemElement)
              subItem.subItems = x
            }
          })
        })

      setItems([...tempItems])
    } else {
      setItems([...initialItems])
    }
  }

  return (
    <>
      <div className='mx-3 mt-3 sidefilter-header'>
        <h6 className='sidefilter-title'>Apply Filters</h6>
        <span className='sidefilter-subtitle'>{props.selected.length} Selected</span>
        <hr />
      </div>

      <div className='sidefilter-body px-2'>
        <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='flex-start'
          data-testid='Filter'
          className='filter'
          spacing={2}
        >
          <Grid item>
            <List className='lists' data-testid='lists' sx={{ width: '100%' }}>
              <>
                {items.map((item, index) => (
                  <NestedItem
                    className='filter-section'
                    data={item}
                    key={index}
                    title={convertToCamelCase(item.label)}
                    name={item.name}
                    onChangeHandler={handleSearchOnSubFilter}
                    subItemsLength={initialItems[index]?.subItems?.length}
                  >
                    <NestedSubitem
                      isLoading={isLoading}
                      onChangeHandler={onChangeHandler}
                      subItems={item.subItems}
                    />
                  </NestedItem>
                ))}
              </>
            </List>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

Filter.propTypes = {
  data: PropTypes.array,
  filteredData: PropTypes.array,
  filters: PropTypes.array,
  setFilteredData: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func
}

export default Filter
